import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";
import { useAppContext } from "../../../core/utils/AppContext";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

type TStory = {
  id: string;
  label: string;
  description?: string;
  story?: string;
  inAnalyze?: boolean;
  updatedAt: Date;
  updatedBy: any;
  analyzeStep?: number;
};

interface IRequiredProps {
  story: TStory & { firstGeneratedImage?: string };
}

interface IOptionalProps {
  viewMode?: "GRID" | "LIST";
}

interface IProps extends IRequiredProps, IOptionalProps {}

export default function Project({ story, viewMode, restricted }: IProps & { restricted: boolean }) {
  const navigate = useNavigate();
  const { removeProject, renameProject } = useAppContext();
  const { setMode, setIsOpen, setModalData } = useBaseModalContext();

  const deleteProjectRequest = async () => {
    await removeProject(story.id);
  };

  const handleRenameProject = () => {
    setMode("RENAME_PROJECT");
    setIsOpen(true);
    setModalData({ story });
  };

  const { openModal } = useBaseModalContext();

  return (
    <div className="border-2 rounded border-transparent w-full bg-neutral-900 cursor-pointer">
      <div className={`flex ${viewMode === "GRID" ? "flex-col" : "flex-row items-center"}`}>
        <div
          className={`relative cursor-pointer ${viewMode === "GRID" ? "w-full" : "w-auto max-h-[90px]"}`}
        >
          {viewMode === "GRID" ? (
            <>
              <button
                className="z-[30] w-[24px] h-[24px] absolute inset-0 flex items-center justify-center text-white bg-neutral-800 transition-bg transform-gpu opacity-0 group-hover:opacity-100 hover:bg-neutral-600 hover:opacity-100"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal({
                    mode: "CONFIRM",
                    data: {
                      title: "Delete Project",
                      description: `Are you sure you want to delete '${story.label}' and all its images? This cannot be undone.`,
                      function: deleteProjectRequest
                    }
                  });
                }}
              >
                <div className="w-[16px] h-[16px] flex items-center justify-center">
                  <TrashIcon className="w-full h-full" />
                </div>
              </button>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <img
                  className="object-cover rounded-t-[3px]"
                  src={story?.firstGeneratedImage ? story.firstGeneratedImage : require("../../../assets/logos/placeholder_result.webp")}
                  alt="project-image"
                />
              </div>
            </>
          ) : (
            <img
              className="object-cover rounded-l-[3px] max-h-[90px]"
              src={require("../../../assets/logos/placeholder_result.webp")}
              alt="project-image"
            />
          )}
        </div>
        <div className="flex flex-col flex-1 px-4 py-2 gap-2 rounded-b">
          <div className="overflow-hidden flex justify-between items-center">
            <button onClick={() => navigate(`/stories/${story?.id}/board`)}>
              <p className="text-xs font-medium uppercase text-neutral-100 line-clamp-2 text-left">{story.label}</p>
              <p className="text-[10px] text-neutral-300 text-left">
                {story?.updatedAt ? new Date(story.updatedAt).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' }) : "No update date"}
              </p>
            </button>
            {!restricted && (
              <div className="flex items-center gap-x-2">
                {story?.analyzeStep === 2  && (
                  <div className="text-neutral-800 text-xs bg-yellow-400 px-2 py-1 rounded-full">
                    analyzing
                  </div>
                )}
                {story?.analyzeStep === 3 && (
                  <div className="bg-blue-400 rounded-full w-2.5 h-2.5">
                  </div>
                )}
                {story?.analyzeStep === 5  && (
                  <div className="text-neutral-800 text-xs bg-yellow-400 px-2 py-1 rounded-full">
                    generating
                  </div>
                )}
                <Menu as="div" className="inline-block text-left z-10">
                  <div>
                    <Menu.Button
                      className="flex items-center text-gray-400 hover:text-gray-50 focus:outline-none"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("Menu button clicked");
                      }}
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5 z-0" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-neutral-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-900 text-neutral-100" : "text-neutral-400",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                console.log("Export clicked");
                                navigate(`/stories/${story.id}/export`, { state: { viewMode: "preview" } });
                              }}
                            >
                              Export
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-900 text-neutral-100" : "text-neutral-400",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                console.log("Rename clicked");
                                handleRenameProject();
                              }}
                            >
                              Rename
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-neutral-900 text-neutral-100" : "text-neutral-400",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                console.log("Delete clicked");
                                openModal({
                                  mode: "CONFIRM",
                                  data: {
                                    title: "Delete Project",
                                    description: `Are you sure you want to delete '${story.label}' and all its images? This cannot be undone.`,
                                    function: deleteProjectRequest
                                  }
                                });
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>

                  </Transition>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

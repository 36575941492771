import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Menu, Switch, Transition } from "@headlessui/react";
import { storyBoardsApiClient } from "../../../core/config/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";
import { useSelector } from "react-redux";
import { ChevronDownIcon, SparklesIcon, StarIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { InpaintCanvas, InpaintCanvasHandle } from "../inpaint-canvas/InpaintCanvas";
import EraserSvg from "../../../assets/stock/eraser.svg";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";
import { useAppContext } from "../../../core/utils/AppContext";
import UniversalTippy from "../universal-tippy/UniversalTippy";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import ImagePlaceholderSVG from '../../../assets/icons/image-placeholder.svg';
import { stylesData } from "../style-selector/styles-data";
import useUserPlan, { freeUserCosts, paidUserCosts } from "../../../core/utils/creditCost";
import GalleryModal from "../modals/gallery-modal/GalleryModal";
import { GptRequestData } from "../../../core/libs/client-api/api/gpt/GptRequest";

interface Image {
  src: string;
  alt: string;
  title: string;
  description: string;
  url: string;
}

interface BrushSettingsProps {
  appContext: {
    boardScreenViewMode: string;
  };
  preferredLineWidth: number;
  preferredStrokeStyle: string;
  setPreferredLineWidth: (width: number) => void;
  setPreferredStrokeStyle: (style: string) => void;
  isEraserOn: boolean;
  setIsEraserOn: (value: boolean) => void;
}

export const cameraAngles = [
  { name: "closeup", color: "bg-red-500" },
  { name: "medium", color: "bg-green-500" },
  { name: "wide", color: "bg-blue-500" },
  { name: "top down", color: "bg-yellow-500" },
  { name: "dutch", color: "bg-purple-500" },
  { name: "low angle", color: "bg-pink-500" },
  { name: "high angle", color: "bg-indigo-500" },
  { name: "side view", color: "bg-teal-500" },
  { name: "aerial", color: "bg-orange-500" }
];

interface IRequiredProps {
  shot: any;
  newImageRequest: Function;
  setScenes: Function;
  setNewInProgress: Function;
  newInProgress: string[];
  setSelectedShot: Function;
  loadingImageUrl: string | null;
  setLoadingImageUrl: Function;
  mainImage: string;
  setMainImage: Function;
  setImageRequest: Function;
}

interface IOptionalProps {
  retrieveAllScenesRequest: Function;
  inGeneration?: boolean;
  scenes?: any;
}

interface IProps extends IRequiredProps, IOptionalProps {
  sceneDescription?: string;
  setImageRequest: (params: { image: any }) => Promise<void>;
}

const BrushSettings: FC<BrushSettingsProps> = ({
  appContext,
  preferredLineWidth,
  preferredStrokeStyle,
  setPreferredLineWidth,
  setPreferredStrokeStyle,
  isEraserOn,
  setIsEraserOn,
}) => {
  const brushSizes: number[] = [3, 7, 15, 30];
  const colors: string[] = ["#EF4444", "#2563EB", "white", "black", "#16A34A"];
  const [currentBrushSizeIndex, setCurrentBrushSizeIndex] = useState<number>(brushSizes.indexOf(15));
  const [currentColorIndex, setCurrentColorIndex] = useState<number>(0);

  const handleBrushSizeClick = () => {
    const nextIndex = (currentBrushSizeIndex + 1) % brushSizes.length;
    setCurrentBrushSizeIndex(nextIndex);
    setPreferredLineWidth(brushSizes[nextIndex]);
  };

  const handleColorClick = () => {
    const nextIndex = (currentColorIndex + 1) % colors.length;
    setCurrentColorIndex(nextIndex);
    setPreferredStrokeStyle(colors[nextIndex]);
  };

  if (appContext.boardScreenViewMode !== "INPAINT") {
    return null; // Return null instead of false
  }

  return (
    <div className="flex gap-x-2 justify-between items-center pt-4">
      <div id="inpaint-tools" className="border border-gray-700 bg-white bg-opacity-5 flex p-2 gap-x-2 rounded">
        <UniversalTippy content="Toggle brush sizes">
          <button
            className={`h-8 w-8 border flex justify-center items-center rounded hover:border-neutral-200 ${preferredLineWidth === brushSizes[currentBrushSizeIndex] ? "border-neutral-500" : "border-white"
              }`}
            onClick={handleBrushSizeClick}
          >
            <div
              className="bg-white rounded-full z-30"
              style={{
                width: `${brushSizes[currentBrushSizeIndex]}px`,
                height: `${brushSizes[currentBrushSizeIndex]}px`,
              }}
            ></div>
          </button>
        </UniversalTippy>
        <UniversalTippy content="Some colours">
          <button
            className={`h-8 w-8 border flex justify-center items-center rounded ${preferredStrokeStyle === colors[currentColorIndex] ? "border-neutral-500" : "border-neutral-500"
              }`}
            onClick={handleColorClick}
            style={{ backgroundColor: colors[currentColorIndex] }}
          ></button>
        </UniversalTippy>
        <button
          className={`h-8 w-fit px-2 border border-neutral-500 hover:border-neutral-200 flex justify-center items-center rounded ${isEraserOn ? "bg-neutral-700" : ""
            }`}
          onClick={() => setIsEraserOn(!isEraserOn)}
        >
          <img className="w-6 h-6" src={EraserSvg} alt="eraser" />
        </button>

      </div>
    </div>
  );
};


export default function Shot({
  shot,
  retrieveAllScenesRequest,
  inGeneration,
  newImageRequest,
  setNewInProgress,
  newInProgress,
  setSelectedShot,
  scenes,
  setScenes,
  loadingImageUrl,
  setLoadingImageUrl,
  mainImage,
  setMainImage,
  setImageRequest,
  sceneDescription
}: IProps) {

  const { userId, availableCredits, account } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits,
    account: state.app?.account
  }));
  const isAdmin = process.env.REACT_APP_ADMIN === 'true';
  const { isFree, isPlan, costs } = useUserPlan();
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [newImagePrompt, setNewImagePrompt] = useState<string>("");
  const [rawPrompt, setRawPrompt] = useState("");
  const [newAngle, setNewAngle] = useState<string>("");
  const [newUserNegativePrompt, setNewUserNegativePrompt] = useState<string>("");
  const [isInputPositivePrompt, setIsInputPositivePrompt] = useState<boolean>(true);

  const [inpaintPositivePrompt, setInpaintPositivePrompt] = useState<string>("");
  const [inpaintNegativePrompt, setInpaintNegativePrompt] = useState<string>("");
  const baseModalContext = useBaseModalContext();
  const [currentStyle, setCurrentStyle] = useState<number>(1);

  const {currentShotImages, setCurrentShotImages} = useAppContext();

  useEffect(() => {
    if (shot?.images) {
      setCurrentShotImages(shot.images);
    }
  }, [shot]);

  const { storyId } = useParams();
  useEffect(() => {
    setMainImage(`${shot?.selectedImage ?? ""}`);
  }, [shot]);

  useEffect(() => {
    const metadata = getMetadata()?.metadata;
    if (metadata) {
      const imageDescription = metadata.imageDescription ?? "";
      setNewImagePrompt(imageDescription === "New Image" ? "" : imageDescription);
      setNewUserNegativePrompt(metadata.userNegativePrompt ?? "");
      setNewAngle(metadata.angle ?? "");
      setCurrentStyle(metadata.styleId ?? 1);
      setRawPrompt(metadata.rawPrompt ?? "");
      setIsRaw(!!metadata.isRaw);
    }
  }, [shot])

  const [isEraserOn, setIsEraserOn] = useState(false);

  const [isRaw, setIsRaw] = useState(false);

  const updateCameraAngleRequest = async ({ value }: { value: string }) => {
    try {
      const result = await storyBoardsApiClient.shot.update({
        id: shot?.id,
        storyId: storyId ?? "",
        angle: value
      });
      if (result.status === 200) {
      } else {
        toast.error("Something went wrong with updating the prompt");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  function getColorForAngle(angle: string) {
    const angleObj = cameraAngles.find(a => a.name === angle);
    return angleObj ? angleObj.color : "bg-gray-500"; // Default color if not found
  }

  const deleteImageRequest = async (id: string) => {
    try {
      const result = await storyBoardsApiClient.image.delete({
        id,
        storyId: storyId ?? ""
      });
      if (result.status === 200) {
        console.log({ result });
        retrieveAllScenesRequest();
      } else {
        toast.error("Something went wrong with image deletion");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const deleteShotRequest = async () => {
    try {
      const result = await storyBoardsApiClient.shot.delete({
        id: shot?.id,
        storyId: storyId ?? ""
      });
      if (result.status === 200) {
        retrieveAllScenesRequest();
      } else {
        toast.error("Something went wrong with deleting the Shot Card");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong with deleting the Shot Card");
    }
  };

  const getMetadata = () => {
    const image = shot.images?.filter((image: any) => image.url === shot.selectedImage);
    return image.length > 0 ? image[0] : null;
  };

  const { isGalleryModalOpen, openGalleryModal, closeGalleryModal } = useAppContext();

  const { openModal } = useBaseModalContext();

  //crossreference the StyleId with the ClinetIndex of that style and return it
  const getClientIndex = (index: number) => {
    const style = stylesData.find((style) => style.index === index.toString());
    return style ? style.clientIndex : null;
  };

  const clientIndex = getClientIndex(currentStyle);

  function SelectedImage() {
    return (
      <div id="selected-image" className="w-full flex">
        <div className="flex-1 flex justify-center items-center overflow-auto">
          {appContext.boardScreenViewMode === "IMG2IMG" ? (
            <div className="w-full aspect-video bg-neutral-700/50 border border-neutral-600 flex flex-col items-center justify-center">
              <img
                src={ImagePlaceholderSVG}
                alt="Upload placeholder"
                className="w-32 h-32 mb-4"
              />
            </div>
          ) : mainImage ? (
            <div className="active-image w-full h-full relative flex justify-center items-center">
              {loadingImageUrl && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
                  <CustomLoadingSpinner size={80} />
                </div>
              )}
              <img
                id="selected"
                width="100%"
                className="cursor-pointer aspect-video object-contain"
                src={shot?.selectedImage ? mainImage : require("../../../assets/logos/placeholder_result.webp")}
                alt="shot-image"
                onClick={() => {
                  openModal({
                    mode: "VIEWER",
                    data: {
                      images: shot?.images,
                      shot,
                      scenes,
                    },
                  });
                }}
              />
            </div>
          ) : (
            <div className="flex h-full bg-gray-200 p-4 items-center justify-center">
              Click on the generate button to create the images
            </div>
          )}
        </div>
      </div>
    );
  }

  const appContext = useAppContext();

  // when we close the inpaint menu clear the inpaint prompt input states
  useEffect(() => {
    if (appContext.boardScreenViewMode === "BUILDER" && (inpaintNegativePrompt !== "" || inpaintPositivePrompt !== "")) {
      setInpaintPositivePrompt("");
      setInpaintNegativePrompt("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext]);


  const handleInpaintRequest = async () => {
    setNewInProgress([...newInProgress, shot?.id]);
    const originalImage = document.getElementById('selected') as HTMLImageElement;
  
    // Use the getCanvasDataURL method from the InpaintCanvas ref
    const base64Mask = inpaintCanvasRef.current?.getCanvasDataURL();
  
    // Ensure base64Mask is available
    if (!base64Mask) {
      console.error("Failed to generate mask image.");
      return;
    }
  
    // Create a new Image object for the mask
    const maskImage = new Image();
    maskImage.src = base64Mask;
  
    maskImage.onload = async () => {
      // Create a new canvas with the same dimensions as the original image
      const fullSizeCanvas = document.createElement('canvas');
      fullSizeCanvas.width = originalImage.naturalWidth;
      fullSizeCanvas.height = originalImage.naturalHeight;
  
      const ctx = fullSizeCanvas.getContext('2d');
      if (ctx) {
        // Draw the mask image onto the full-size canvas, scaling it up
        ctx.drawImage(
          maskImage,
          0, 0, maskImage.width, maskImage.height,
          0, 0, fullSizeCanvas.width, fullSizeCanvas.height
        );
  
        // Generate the base64 string from the processed full-size canvas
        const base64MaskFullSize = fullSizeCanvas.toDataURL();
  
        const selectedImage = shot?.images.find((image: any) => image.url === shot.selectedImage);
        const storyId = scenes.find((scene: any) => scene.shots.find((s: any) => s?.id === shot?.id))?.id;
  
        const metadata = {
          inpaintMask: base64MaskFullSize,
          newAngle: selectedImage?.metadata?.angle,
          newImagePrompt: inpaintPositivePrompt,
          rawPrompt: rawPrompt,
          isRaw: true,
        };
  
        try {
          const result = await storyBoardsApiClient.shot.newImage({
            id: shot?.id,
            storyId: storyId ?? "",
            type: "INPAINT",
            metadata
          });
  
          if (result.status === 201) {
            console.log(result.data);
            sendEventToGoogleTagManager("inpaint_generation_success", {
              story_id: storyId,
              outcome: "success"
            }, userId, availableCredits);
  
            const inpaintCost = account?.subscription?.tier?.id === "tier-free" ? freeUserCosts.inpaint : paidUserCosts.inpaint;
            appContext.setAiCreditsSpentSinceReload(prev => prev + inpaintCost);
            appContext.setInpaintCreditsSpentSinceReload(prev => prev + 1);
  
            setScenes((prevState: any[]) => {
              return prevState.map((scene: any) => {
                return {
                  ...scene,
                  shots: scene?.shots?.map((shot: any) => {
                    if (shot?.id === result?.data?.shot?.id) {
                      return result.data.shot;
                    }
                    return shot;
                  })
                };
              });
            });
  
            result?.data?.shot && setSelectedShot(result?.data?.shot);
            appContext.setInpaintResponseImage(result?.data);
  
            toast("A new image created successfully");
          } else {
            toast.error("Something went wrong");
          }
        } catch (error) {
          console.log({ error });
          toast.error("Something went wrong");
        }
        setNewInProgress((prevState: string[]) => [...prevState].filter((shotId) => shotId !== shot?.id));
      }
    };
  
    // Handle mask image loading error
    maskImage.onerror = () => {
      console.error("Failed to load mask image.");
      toast.error("Something went wrong with generating the mask image.");
      setNewInProgress((prevState: string[]) => [...prevState].filter((shotId) => shotId !== shot?.id));
    };
  };
  const DEFAULT_IMAGE_URL = "https://story-board-images.s3.us-west-1.amazonaws.com/placeholder_result.webp";

  const handleImg2ImgRequest = async () => {
    setNewInProgress([...newInProgress, shot?.id]);

    const selectedImage = shot?.images.find((image: any) => image.url === shot.selectedImage);
    const storyId = scenes.find((scene: any) => scene.shots.find((s: any) => s?.id === shot?.id))?.id;

    if (!appContext.droppedFileImg2Img && !appContext.galleryImg2ImgSource) {
      toast.error("You need to add a source image first (either upload a file or select from the gallery)");
      setNewInProgress((prevState: string[]) => [...prevState].filter((shotId) => shotId !== shot?.id));
      return;
    }

    let base64Img2Img: string = "";

    if (appContext.droppedFileImg2Img) {
      const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
      if (!validImageTypes.includes(appContext.droppedFileImg2Img.type)) {
        toast.error("Unsupported image format. Please upload a PNG, JPEG, or WebP image.");
        setNewInProgress((prevState: string[]) => [...prevState].filter((shotId) => shotId !== shot?.id));
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(appContext.droppedFileImg2Img as Blob);

      reader.onloadend = async () => {
        const img = new Image();
        img.src = reader.result as string;
        
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx) {
            canvas.width = 1360;
            canvas.height = 768;
            const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
            const newWidth = img.width * scale;
            const newHeight = img.height * scale;
            const x = (canvas.width - newWidth) / 2;
            const y = (canvas.height - newHeight) / 2;
            ctx.drawImage(img, x, y, newWidth, newHeight);
            base64Img2Img = canvas.toDataURL('image/png');

            const metadata = {
              img2ImgInputImage: base64Img2Img,
              newAngle: selectedImage?.metadata?.angle,
              newImagePrompt: isRaw ? rawPrompt : newImagePrompt,
              rawPrompt: isRaw ? rawPrompt : "",
              isRaw: isRaw,
            };

            await sendImg2ImgRequest(metadata, storyId);
          }
        };
      };
    } else if (appContext.galleryImg2ImgSource) {
      // Start of logging for gallery image processing
      console.log("Gallery image URL selected:", appContext.galleryImg2ImgSource);

      const img = new Image();
      img.crossOrigin = "Anonymous"; // Allow cross-origin loading
      img.src = `${appContext.galleryImg2ImgSource}?timestamp=${new Date().getTime()}`;

      // Log when the image starts loading
      img.onloadstart = () => {
        console.log("Starting to load gallery image from URL...");
      };

      // Log if there's an error during image loading
      img.onerror = (error) => {
        console.error("Error loading image from URL:", appContext.galleryImg2ImgSource, error);
        toast.error("Failed to load image from the gallery. Please check the image URL.");
      };

      // Once the image loads, proceed with canvas operations
      img.onload = async () => {
        console.log("Gallery image loaded successfully. Now converting to base64...");

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          console.log("Canvas context obtained. Resizing image to 1360x768 for base64 conversion.");

          canvas.width = 1360;
          canvas.height = 768;

          // Log the original image dimensions
          console.log("Original Image Width:", img.width, "Height:", img.height);

          const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
          const newWidth = img.width * scale;
          const newHeight = img.height * scale;

          // Log the new dimensions after scaling
          console.log("Scaled Image Width:", newWidth, "Height:", newHeight);

          const x = (canvas.width - newWidth) / 2;
          const y = (canvas.height - newHeight) / 2;

          console.log("Drawing image on canvas at position (x, y):", x, y);

          // Draw the image onto the canvas
          ctx.drawImage(img, x, y, newWidth, newHeight);
          
          // Convert the canvas to base64
          base64Img2Img = canvas.toDataURL('image/png');
          console.log("Image successfully converted to base64 string.");

          const metadata = {
            img2ImgInputImage: base64Img2Img, // Now we use the base64 instead of the URL
            newAngle: selectedImage?.metadata?.angle,
            newImagePrompt: isRaw ? rawPrompt : newImagePrompt,
            rawPrompt: isRaw ? rawPrompt : "",
            isRaw: isRaw,
          };

          console.log("Sending metadata with base64 image to API:", metadata);

          // Send the request with the metadata
          await sendImg2ImgRequest(metadata, storyId);
          console.log("Request sent successfully.");
        } else {
          console.error("Failed to get canvas context.");
        }
      };
    }
  };
  
  const sendImg2ImgRequest = async (metadata: any, storyId: string) => {
    console.log("sendImg2ImgRequest")
    try {
      const result = await storyBoardsApiClient.shot.newImage({
        id: shot?.id,
        storyId: storyId ?? "",
        type: "IMG2IMG",
        metadata
      });
  
      if (result.status === 201) {
        console.log(result.data);
        sendEventToGoogleTagManager("img2img_success", { story_id: storyId, style_id: result?.data?.story?.style }, userId, availableCredits);
  
        const img2imgCost = account?.subscription?.tier?.id === "tier-free" ? freeUserCosts.img2Img : paidUserCosts.img2Img;
        appContext.setAiCreditsSpentSinceReload((prev) => prev + img2imgCost);
        appContext.setImg2ImgCreditsSpentSinceReload((prev) => prev + 1);
  
        setScenes((prevState: any[]) => prevState.map((scene: any) => ({
          ...scene,
          shots: scene?.shots?.map((shot: any) => shot?.id === result?.data?.shot?.id ? result.data.shot : shot)
        })));
  
        result?.data?.shot && setSelectedShot(result?.data?.shot);
        setMainImage(result.data.shot.selectedImage);
        appContext.setBoardScreenViewMode("BUILDER");
        toast("A new image created successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  
    setNewInProgress((prevState: string[]) => prevState.filter((shotId) => shotId !== shot?.id));
  };
  ;
  
  function MainImageOptions() {
    return (
      <>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <UniversalTippy content="Previously generated images">
            <button id="gallery-button"
              className="relative inline-flex items-center rounded-l-md bg-neutral-700 px-3 py-2 text-xs font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500"
              onClick={openGalleryModal}
            >
              Gallery
            </button>
          </UniversalTippy>

          {/* GalleryModal Component */}
          {isGalleryModalOpen && (
            <GalleryModal
              isOpen={isGalleryModalOpen}
              closeModal={closeGalleryModal}
              images={currentShotImages}
              loadingImageUrl={loadingImageUrl}
              setImageRequest={setImageRequest}
            />
          )}
          <UniversalTippy
            content={
              <>
                Create characters to use in your story
              </>
            }
            videoId="H-6wV-x-d3E"
          >
            <button
              type="button"
              id="character-button"
              className="relative -ml-px inline-flex items-center bg-neutral-700 px-3 py-2 text-xs font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500"
              onClick={() => openModal({ mode: "CAST" })}
            >
              Characters
            </button>
          </UniversalTippy>
          <UniversalTippy content="Choose the visual style for your storyboard">
            <button
              type="button"
              id="style-button"
              className="relative -ml-px inline-flex items-center bg-neutral-700 px-3 py-2 text-xs font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500"
              onClick={() => {
                console.log("style");
                openModal({ mode: "STYLE" });
              }}
            >
              Look
            </button>
          </UniversalTippy>
          <UniversalTippy
            content="Tools to edit your Image"
          >
            <div>
              <button
                type="button"
                id="edit-button"
                className={`relative -ml-px inline-flex items-center bg-neutral-700 px-3 py-2 text-xs font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 
                  ${mainImage === DEFAULT_IMAGE_URL ? 'bg-neutral-600 text-neutral-400 focus:outline-none' : 'bg-neutral-700 hover:bg-neutral-500'}
                `}
                onClick={() => {
                  if (mainImage !== DEFAULT_IMAGE_URL) {
                    appContext.setBoardScreenViewMode("INPAINT");
                  }
                }}
                disabled={mainImage === DEFAULT_IMAGE_URL}
              >
                Edit
              </button>
            </div>
          </UniversalTippy>
          <UniversalTippy content="Helpful features to get the Shot you want">
            <button
              type="button"
              id="assist-button"
              className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-xs font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 bg-neutral-700 hover:bg-neutral-500`}
              onClick={() => {
                appContext.setBoardScreenViewMode("IMG2IMG")
              }}
            >
              Assist
            </button>
          </UniversalTippy>
        </span>
      </>
    );
  }

 const inpaintCanvasRef = useRef<InpaintCanvasHandle>(null);

  const [preferredStrokeStyle, setPreferredStrokeStyle] = useState("#EF4444");

  const [preferredLineWidth, setPreferredLineWidth] = useState(5);

  const getRowsBasedOnScreenWidth = () => {
    if (window.innerHeight >= 768) { // md and up
      return 4;
    } else if (window.innerHeight >= 640) { // sm and up
      return 2;
    } else { // base
      return 4;
    }
  };

  const [rows, setRows] = useState(getRowsBasedOnScreenWidth());

  useEffect(() => {
    const handleResize = () => {
      setRows(getRowsBasedOnScreenWidth());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // RAW prompt option
  const getRawPrompt = () => {
    const description = sceneDescription || '____';
    const prompt = newImagePrompt || '____';
    return `a ${newAngle} shot, of ${prompt}, in ${description}`;
  };

  // set prompt to RAW on isRaw
  useEffect(() => {
    if (isRaw && rawPrompt === "") {
      setRawPrompt(getRawPrompt());
    }
  }, [isRaw, newAngle, newImagePrompt, sceneDescription]);

  // check if image Metadata isRaw exists and true, then update isRaw local state
  useEffect(() => {
    const metadata = getMetadata()?.metadata;
    if (metadata && 'isRaw' in metadata) {
      setIsRaw(metadata.isRaw === true);
      setRawPrompt(metadata.rawPrompt)
    } else {
      setIsRaw(false);
    }
  }, [shot?.selectedImage]);

  let aiRawPrompt: string;

  const handleAiAssistantClick = async () => {
    console.log("AI assistant");
    console.log("Current scene description:", sceneDescription);
    console.log("CAMERA:", newAngle);
    console.log("isRaw:", isRaw);
    console.log("newImagePrompt:", newImagePrompt);
    console.log("rawPrompt:", rawPrompt);

    setIsAiLoading(true);
  
    try {
      const aiPrompt = await sendGptRequest(); 
  
      setIsRaw(true); 
      if (aiPrompt){  
        setRawPrompt(aiPrompt); 
      }
    } catch (error) {
      console.error("AI Assistant Error:", error);
      toast.error("Failed to generate AI prompt.");
    } finally {
      setIsAiLoading(false); 
      appContext.setAiCreditsSpentSinceReload((prev) => prev + 1);
    }
  };
  

  const sendGptRequest = async (
  ) => {
    console.log("request")
    const gptData: GptRequestData = {
      storyId: storyId || "",
      requestType: "userAiPrompt",
      data: {
        cameraAngle: newAngle,
        sceneDescription: sceneDescription || "",
        prompt: isRaw ? rawPrompt : newImagePrompt, 
      },
    };
  
    // Instantiate the GptRequest class
    const gptRequest = storyBoardsApiClient.gptrequest; 
  
    // Send the request
    try {
      console.log("trying")
      console.log("gptRequest object:", gptRequest);
      console.log("Is processPrompt a function?", typeof gptRequest.processPrompt === 'function');

      const response = await gptRequest.processPrompt(gptData);
      console.log("Shots.tsx. GPT Response:", response);
      aiRawPrompt = response.prompt || "";

      return aiRawPrompt
    } catch (error) {
      console.error("Error while processing the GPT request:", error);
    } finally {
      sendEventToGoogleTagManager("prompt_assistant_success", {
      }, userId, availableCredits)
    }
  };

  return (
    <>
      <div id="image-creator" className="imagecreator relative text-neutral-100 w-full h-full bg-neutral-900">
        {newInProgress?.includes(shot?.id) || inGeneration ? (
          <div className="absolute top-0 left-0 w-full h-full z-20 flex items-center justify-center">
            {/* Overlay with Blurred Background */}
            <div
              className="w-full h-full absolute top-0 left-0 bg-neutral-800 rounded-lg opacity-80 backdrop-blur"></div>

            {/* Spinner */}
            <CustomLoadingSpinner size={80} />

            {/* Text */}
            <div className="absolute mt-44 text-lg text-neutral-100 z-40">Making a new Image ...</div>
          </div>
        ) : null}
        <div className="h-full flex flex-row ">
          <div className="flex flex-1 px-4 sm:px-8 pb-10 h-full">
            <div className="w-full gap-4 lg:px-8 h-full flex-col"> {/* Image Column */}
              <div className="flex justify-between">
                {appContext.boardScreenViewMode === "INPAINT" && (
                  <div>
                    <button id="gallery-button"
                      className="relative inline-flex items-center rounded-l-md bg-neutral-300 px-3 py-2 text-xs sm:text-sm font-semibold text-neutral-900 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500"
                      onClick={() => appContext.setBoardScreenViewMode("BUILDER")}
                    >
                      Edit
                    </button>
                    <button className="relative -ml-px inline-flex items-center rounded-r-md bg-neutral-700 px-3 py-2 text-xs sm:text-sm font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500" onClick={() => appContext.setBoardScreenViewMode("INPAINT")}>
                      Inpaint
                    </button>
                  </div>
                )}
                {appContext.boardScreenViewMode === "IMG2IMG" && (
                  <div>
                    <UniversalTippy content="Helpful features to get the Shot you want">
                      <button id="gallery-button"
                        className="relative inline-flex items-center rounded-l-md bg-neutral-300 px-3 py-2 text-xs sm:text-sm font-semibold text-neutral-900 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500"
                        onClick={() => appContext.setBoardScreenViewMode("IMG2IMG")}
                      >
                        Assist
                      </button>
                    </UniversalTippy>
                    <button className="relative -ml-px inline-flex items-center rounded-r-md bg-neutral-700 px-3 py-2 text-xs sm:text-sm font-semibold text-neutral-200 ring-1 ring-inset ring-neutral-500 hover:bg-neutral-500"
                      onClick={() => {
                        if (appContext.boardScreenViewMode !== "IMG2IMG") {
                          appContext.setBoardScreenViewMode("IMG2IMG")
                          appContext.setDroppedFileImg2Img(undefined);
                        };
                      }}
                    >
                      IMG2IMG
                    </button>
                  </div>
                )}
                {appContext.boardScreenViewMode !== "BUILDER" && (
                  <button
                    className="font-bold text-lg border-gray-700 border bg-white bg-opacity-5 w-8 h-8 rounded-full"
                    onClick={() => {
                      appContext.setBoardScreenViewMode("BUILDER");
                      if (appContext.droppedFileImg2Img !== undefined) {
                        appContext.setDroppedFileImg2Img(undefined);
                      };
                    }}
                  >
                    <XMarkIcon></XMarkIcon>
                  </button>
                )}
                {appContext.boardScreenViewMode === "BUILDER" && (
                  <div className="flex justify-between items-center w-full">
                    <MainImageOptions />

                    <Menu as="div" className="relative inline-block text-left">
                      <div className="flex gap-x-2 items-center">
                        <p className="text-xs text-gray-200">Camera:</p>
                        <UniversalTippy className="w-[285px]" content="Choose your desired camera angle">
                          <Menu.Button
                            id="camera-angle"
                            className="inline-flex items-center gap-x-1 rounded-md bg-neutral-700 px-3 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-neutral-600"
                          >
                            <span className="flex items-center">
                              <span
                                className={`mr-2 h-3 w-3 rounded-full ${getColorForAngle(newAngle)}`}
                              />
                              {newAngle}
                            </span>
                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                          </Menu.Button>
                        </UniversalTippy>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {cameraAngles.map((angle) => (
                              <Menu.Item key={angle.name}>
                                {({ active }) => (
                                  <button
                                    className={`${active ? 'bg-neutral-700 text-white' : 'text-gray-300'
                                      } flex w-full items-center px-4 py-2 text-sm`}
                                    onClick={() => setNewAngle(angle.name)}
                                  >
                                    <span
                                      className={`mr-3 h-3 w-3 rounded-full ${angle.color}`}
                                    />
                                    {angle.name}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>
              <div className={`flex-1 font-medium pt-6`}>
                Scene:{" "}
                {scenes.findIndex((scene: any) =>
                  scene.shots.some((shotInScene: any) => shotInScene?.id === shot?.id)
                ) + 1}{" "}
                / Shot: {shot?.index + 1}
              </div>
              <div>
                <div className="flex flex-row items-center justify-between text-center lg:text-left">
                </div>

                <div className="relative">
                  {SelectedImage()}
                  {appContext.boardScreenViewMode === "INPAINT" && (
                    <InpaintCanvas
                      className="z-10 absolute top-0 left-0"
                      preferredLineWidth={preferredLineWidth}
                      preferredStrokeStyle={preferredStrokeStyle}
                      ref={inpaintCanvasRef}
                      isEraserOn={isEraserOn}
                      isInpaintMode={true}
                    />
                  )}
                  <span className="absolute top-1 right-1 text-xs bg-neutral-800 text-neutral-200 p-1">
                    Style: {clientIndex}
                  </span>
                </div>
                {appContext.boardScreenViewMode === "INPAINT" && (
                  <div className="flex gap-x-2 justify-between items-center">
                    <BrushSettings
                      appContext={appContext}
                      preferredLineWidth={preferredLineWidth}
                      preferredStrokeStyle={preferredStrokeStyle}
                      setPreferredLineWidth={setPreferredLineWidth}
                      setPreferredStrokeStyle={setPreferredStrokeStyle}
                      isEraserOn={isEraserOn}
                      setIsEraserOn={setIsEraserOn}
                    />

                    <button className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4 ml-2"
                      onClick={() => inpaintCanvasRef.current && (inpaintCanvasRef.current as any)?.clearCanvas()}
                    >
                      Reset
                    </button>
                  </div>
                )
                }
                <div id="image-prompt" className={"flex flex-col mt-6"}>
                  <div className="mb-4 flex justify-between items-center">
                    <p className="text-sm md:font-md">
                      {appContext.boardScreenViewMode === "INPAINT" ? "InPaint prompt" : isInputPositivePrompt ? "Describe Your Image:" : "Optional: Describe what you don't want in your image"}
                    </p>
                    <div className="flex gap-x-4 items-center">
                      <label htmlFor="switch-positive-negative-prompt">
                        {isInputPositivePrompt ? "positive" : "negative"}
                      </label>
                      <UniversalTippy content="Switch between positive or negative prompts">
                        <Switch
                          id="switch-positive-negative-prompt"
                          checked={isInputPositivePrompt}
                          onChange={() => setIsInputPositivePrompt((prev) => !prev)}
                          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                        >
                          <span className="sr-only">Use setting</span>
                          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md" />
                          <span aria-hidden="true" className={`${isInputPositivePrompt ? "bg-green-600" : "bg-red-500"} pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`} />
                          <span aria-hidden="true" className={`${isInputPositivePrompt ? "translate-x-5" : "translate-x-0"} pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out`} />
                        </Switch>
                      </UniversalTippy>
                      <div className="flex">
                      {/* RAW Button */}
                      <UniversalTippy content="Edit the raw prompt">
                        <div>
                        <button
                          className={`px-2 py-1 text-sm border ${
                            isRaw
                              ? 'border-neutral-200 bg-neutral-200 hover:bg-neutral-50 text-neutral-800'
                              : 'border-neutral-200 text-neutral-200 bg-transparent'
                          } ${
                            appContext.boardScreenViewMode === 'INPAINT' ? 'opacity-50 cursor-not-allowed' : ''
                          } rounded-l-md`}
                            onClick={() => {
                              if (appContext.boardScreenViewMode !== "INPAINT") {
                                if (!isRaw && !rawPrompt) {
                                  setRawPrompt(getRawPrompt());
                                }
                                setIsRaw(!isRaw);
                              }
                            }}
                            disabled={appContext.boardScreenViewMode === "INPAINT"}
                          >
                            RAW
                          </button>
                        </div>
                      </UniversalTippy>

                      {/* AI Button */}
                      <UniversalTippy 
                        content={
                          appContext.boardScreenViewMode === "INPAINT" || 
                          (isRaw && !rawPrompt) || 
                          (!isRaw && !newImagePrompt) 
                            ? "write some text to use the AI prompt Assiatnt" 
                            : "AI prompt Assistant"
                        }
                      >
                        <div>
                        <button
                          className={`px-2 py-1 text-sm border border-neutral-300 
                            ${appContext.boardScreenViewMode === "INPAINT" || (isRaw && !rawPrompt) || (!isRaw && !newImagePrompt)
                              ? 'cursor-not-allowed bg-neutral-200 opacity-50' 
                              : 'bg-neutral-200 hover:bg-neutral-50'}  
                            text-neutral-800 rounded-r-md flex items-center justify-center`}
                          onClick={handleAiAssistantClick}
                          disabled={
                            appContext.boardScreenViewMode === "INPAINT" ||  // Disable in INPAINT mode
                            (isRaw && !rawPrompt) ||                        // Disable in RAW mode if rawPrompt is empty
                            (!isRaw && !newImagePrompt)                     // Disable in normal mode if newImagePrompt is empty
                          }
                        >
                           {isAiLoading ? (
                            <CustomLoadingSpinner size={20} color="#1F2937" /> 
                          ) : (
                            <SparklesIcon className="h-5 w-5" />
                          )}
                        </button>
                        </div>
                      </UniversalTippy>

                    </div>
                    </div>
                  </div>
                  <div className="relative">
                    {isInputPositivePrompt ? (
                      ["BUILDER", "IMG2IMG"].includes(appContext.boardScreenViewMode) ? (
                        <textarea
                          key={getMetadata()?.id}
                          className={"p-2 w-full h-full bg-white rounded border-gray-700 bg-opacity-5"}
                          value={isRaw ? rawPrompt : newImagePrompt}
                          rows={rows}
                          placeholder={isRaw ? "Raw prompt" : "Your image prompt: 'WHO, wearing WHAT is doing WHAT'"}
                          onChange={(event) => {
                            if (isRaw) {
                              setRawPrompt(event.target.value);
                            } else {
                              setNewImagePrompt(event.target.value);
                            }
                          }}
                        />
                      ) : (
                        <UniversalTippy content="Enter what you want to see in the painted area">
                            <textarea
                              key={getMetadata()?.id}
                              className="p-2 w-full h-full bg-white rounded border-gray-700 bg-opacity-5"
                              value={inpaintPositivePrompt}
                              rows={rows}
                              placeholder={"Describe what you want to see in the painted area"}
                              onChange={(event) => {
                                if (isRaw) {
                                  setRawPrompt(event.target.value);
                                  setInpaintPositivePrompt(event.target.value);
                                } else {
                                  setNewImagePrompt(event.target.value);
                                }
                              }}
                            />
                        </UniversalTippy>
                      )
                    ) : (
                      ["BUILDER", "IMG2IMG"].includes(appContext.boardScreenViewMode) ? (
                        <textarea
                          key={getMetadata()?.id}
                          className={"p-2 w-full h-full bg-white rounded border-gray-700 bg-opacity-5"}
                          value={newUserNegativePrompt}
                          rows={rows}
                          placeholder="Optional: Describe what you don't want in your image"
                          onChange={(event) => setNewUserNegativePrompt(event.target.value)}
                        />
                      ) : (
                        <textarea
                          key={getMetadata()?.id}
                          className={"p-2 w-full h-full bg-white rounded border-gray-700 bg-opacity-5"}
                          value={inpaintNegativePrompt}
                          rows={rows}
                          placeholder="Optional: Describe what you don't want in your image"
                          onChange={(event) => setInpaintNegativePrompt(event.target.value)}
                        />
                      )
                    )}
                  </div>
                </div>

                <div className="flex flex-row w-full gap-2 mt-8 pb-8">
                  <button
                    type="button"
                    className={`generate-image-button w-full flex items-center justify-center relative ${newInProgress?.includes(shot?.id) ? "bg-gray-600" : "bg-sky-500 hover:bg-sky-600"
                      } h-30 w-30 rounded px-2.5 py-1.5 text-sm font-semibold text-neutral-100`}
                    onClick={() => {
                      if (!(account?.stats?.availableCredits === 0) || isAdmin) {
                        // regular new image
                        if (!["INPAINT", "IMG2IMG"].includes(appContext.boardScreenViewMode)) {
                          newImageRequest({
                            shot,
                            type: "NEW",
                            metadata: {
                              newAngle,
                              newImagePrompt: isRaw ? rawPrompt : newImagePrompt,
                              rawPrompt: isRaw ? rawPrompt : "",
                              newUserNegativePrompt,
                              isRaw,
                              styleClientIndex: baseModalContext.styleClientIndex,
                            }
                          });
                        } else if (appContext.boardScreenViewMode === "IMG2IMG") {
                          // img2img
                          if (account?.subscription?.tier?.id === "tier-free" && !isAdmin) {
                            const userImg2ImgCredits = account?.stats?.availableImg2ImgCredits ?? 3;
                            if ((userImg2ImgCredits - appContext.img2ImgCreditsSpentSinceReload) < 1) {
                              openModal({ mode: "SUBSCRIBE", data: {}, trigger: "IMG2IMG" });
                            } else {
                              handleImg2ImgRequest();
                            }
                          } else {
                            handleImg2ImgRequest();
                          }
                        } else {
                          // inpaint
                          if (account?.subscription?.tier?.id === "tier-free" && !isAdmin) {
                            const userInpaintCredits = account?.stats?.availableInpaintCredits ?? 3;
                            if ((userInpaintCredits - appContext.inpaintCreditsSpentSinceReload) < 1) {
                              openModal({ mode: "SUBSCRIBE", data: {}, trigger: "INPAINT" });
                            } else {
                              handleInpaintRequest()
                            }
                          } else {
                            handleInpaintRequest();
                          }
                        }
                      } else {
                        toast.info("You ran out of Credits. Subscribe to a different plan to generate more images!");
                      }
                    }}

                  >
                    <span className="flex items-center gap-x-2">
                      <p>
                        {
                          (account?.stats?.availableCredits === 0) ? "Subscribe to generate more images!" : (
                            newInProgress?.includes(shot?.id) ? "" : "Create New Image"
                          )
                        }
                      </p>
                      {
                        (account?.stats?.availableCredits === 0) && <StarIcon className="text-yellow-500 h-4 w-4" />
                      }
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

import React, { useMemo } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/material.css";
import "tippy.js/themes/translucent.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/scale-subtle.css";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import { useSelector } from "react-redux";
import { useBaseModalContext } from "../modals/base-modal/BaseModalContext";
import { useAppContext } from "../../../core/utils/AppContext";

interface TippyUniversalProps {
  content: React.ReactNode;
  children?: React.ReactElement;
  className?: string;
  videoId?: string;
}

const UniversalTippy: React.FC<TippyUniversalProps> = ({
  children,
  content,
  className,
  videoId,
  ...props
}) => {
  const { user } = useAppContext(); 

  const { userId, availableCredits } = useMemo(() => ({
    userId: user?.id,
    availableCredits: user?.stats?.availableCredits,
  }), [user]);

  const { setMode, setIsOpen, setYoutubeId } = useBaseModalContext();

  const handleShow = () => {
    sendEventToGoogleTagManager("show_tippy", {
      content: content
    }, userId, availableCredits);
  };

  const handleOpenModal = (event:any) => {
    event.preventDefault();
    if (videoId) {
      setYoutubeId(videoId);
      console.log("setting video id to: ", videoId)
      setMode("VIDEO");
      setIsOpen(true);
    }
  };

  return (
    <Tippy
      {...props}
      theme="light-border"
      appendTo={document.body} 
      className={`${className} p-2 `}
      content={
        <div>
          {content}
          {videoId && (
            <div className="mt-2 flex justify-between">
              <a
                href="#"
                onClick={handleOpenModal}
                className="text-blue-600 hover:underline font-semibold"
              >
                Watch a short video
              </a>
            </div>
          )}
        </div>
      }
      delay={[600, 400]}
      interactive={true}
      animation="scale-subtle"
      touch={false}
      onShow={handleShow}
      trigger="mouseenter"
    >
      {children}
    </Tippy>
  );
};

export default UniversalTippy;

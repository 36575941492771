import { useEffect, useMemo } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import routes from "./core/routes";
import useAuth from "./core/hooks/auth";
import Loading from "./app/components/loading";
import { initializeGoogleTagManager } from "./core/utils/googleTagManager";
import HotjarTracking from "./core/utils/HotJarTracking";



function App() {
  const [data, loading] = useAuth();


  const finalRoutes:RouteObject[] = useMemo(() => {
    if(loading){
      return []
    }
    return routes(data !== null);
  },[data, loading])
  
  const routing = useRoutes(finalRoutes);


  useEffect(() => {
    if (data !== null) {
      initializeGoogleTagManager("GTM-5HZFSTS9");
    }

    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, [data]);

  return (
    <>
    <HotjarTracking />
      {loading ? (
        <Loading />
      ) : (
        <div className="min-h-screen md:h-full bg-[#181818]">
          <div className="story-boards-app md:h-full overflow-auto ">
            {routing}
          </div>
        </div>
      )}
    </>
  );
}

export default App;

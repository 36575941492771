import React, { useEffect, useState } from "react";
import Project from "../../components/project";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import store, { TReduxStore } from "../../../core/redux/store";
import { setModal } from "../../../core/redux/actions/modalManagerActions";
import { ENUM_MODAL_TYPE } from "../../../core/types/modal.type";
import { storyBoardsApiClient } from "../../../core/config/api";
import { setAccount } from "../../../core/redux/actions/accountActions";
import { useSelector } from "react-redux";
import { getTeams } from "../../../core/redux/actions/teamsActions";
import { toast } from "react-toastify";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Templates from "../../components/templates/Templates";
import { CustomLoadingSpinner } from "../../components/loading/CustomLoadingSpinner";
import { sendEventToGoogleTagManager } from "../../../core/utils/googleTagManager";
import Navbar from "../../..//app/components/navbar/Navbar";
import useAuth from "../../../core/hooks/auth";
import { useBaseModalContext } from "../../components/modals/base-modal/BaseModalContext";
import { useNavigate } from "react-router";
import UniversalTippy from "../../components/universal-tippy/UniversalTippy";
import { useAppContext } from "../../../core/utils/AppContext";
import useUserPlan from "../../../core/utils/creditCost";

const userNavigation = [
  { name: "Theme", href: "#" },
  { name: "Your profile", href: "#" },
  { name: "Sign out" }
];

type Props = {};

function Projects({ }: Props) {

  const { projects, setProjects, fetchProjects } = useAppContext();

  const [clientId, setClientId] = useState<string | null>(null);


  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { isTemplateLoading, setIsTemplateLoading } = useAppContext();
  const { account, teams, userId, availableCredits, isFirstLogin } = useSelector((state: TReduxStore) => ({
    account: state.app.account,
    teams: state.app.teams?.teams,
    userId: state.app.account?.id,
    availableCredits: state.app.account.stats.availableCredits,
    isFirstLogin: state.app.account?.firstLogin,
  }));


  const isAdmin = process.env.REACT_APP_ADMIN === 'true';
  const { isFree } = useUserPlan();
  const [viewMode, setViewMode] = useState<"GRID" | "LIST">("GRID");

  const isModalShown = () => {
    return localStorage.getItem('isSubscribeModalShown') === 'true';
  };

  const setModalShown = () => {
    localStorage.setItem('isSubscribeModalShown', 'true');
  };

  const setTeamRequest = async ({ teamId }: { teamId: string }) => {
    try {
      const result = await storyBoardsApiClient.user.setTeam({ teamId });
      if (result.status === 201) {

        sendEventToGoogleTagManager("create_team_success", {
          event_category: "Project Management",
          event_action: "create team",
          event_label: "Success",
          value: true
        }, userId, availableCredits);
        // @ts-ignore
        store.dispatch(setAccount({ data: result.data }));
      } else {
        sendEventToGoogleTagManager("create_team_fail", {
          event_category: "Project Management",
          event_action: "create team",
          event_label: "success",
          value: false
        }, userId, availableCredits);
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    //console.log("useEffect triggered");
    //console.log("Loading:", loading);
    //console.log("Is First Login:", isFirstLogin);
    //console.log("Is Modal Shown:", isModalShown());

    if (!loading && isFirstLogin && !isModalShown()) {
      console.log("Conditions met, opening modal");

      openModal({
        mode: "FIRST_LOGIN_EMAIL_CONFIRM",
        data: {
          title: "",
          description: "",
          //@ts-ignore
          function: () => {
            console.log("Modal function executed");
          }
        }
      });

      setModalShown();
      console.log("Modal shown state set");
    }
  }, [isFirstLogin, loading]);

  useEffect(() => {
    if (isFirstLogin) {
      sendEventToGoogleTagManager("signup_success", {
        event_category: "Account",
        event_action: "Signup",
        event_label: "success",
        value: true
      }, userId, availableCredits);
    }
  }, [isFirstLogin]);

  const [projectSortCriteria, setProjectSortCriteria] = useState<"name" | "modified">(
    localStorage.getItem("projectSortCriteria") === "name"
      ? "name"
      : "modified"
  );

  const [projectSortDirection, setProjectSortDirection] = useState<"ascending" | "descending">(
    localStorage.getItem("projectSortDirection") === "ascending"
      ? "ascending"
      : "descending"
  );


  const toggleProjectSortCriteria = () => {
    const newCriteria = projectSortCriteria === "modified" ? "name" : "modified";
    localStorage.setItem("projectSortCriteria", newCriteria);
    setProjectSortCriteria(newCriteria);
  };

  const sortProjects = () => {
    let sortedStories = [...projects];

    // Determine the sorting direction based on projectSortDirection state
    const direction = projectSortDirection === "ascending" ? 1 : -1;

    if (projectSortCriteria === "modified") {
      sortedStories.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return direction * (dateA - dateB);
      });
    } else {
      // Sort by name (label property)
      sortedStories.sort((a, b) => {
        const nameA = a.label || "";
        const nameB = b.label || "";
        return direction * nameA.localeCompare(nameB);
      });
    }

    setProjects([...sortedStories]);
  };

  const mostRecentProjectId = projects.reduce((latest, project) => {
    return new Date(latest.updatedAt) > new Date(project.updatedAt) ? latest : project;
  }, projects[0])?.id;

  useEffect(() => {
    sortProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSortCriteria, projectSortDirection]);

  const toggleProjectSortDirection = () => {
    const newDirection = projectSortDirection === "ascending" ? "descending" : "ascending";
    localStorage.setItem("projectSortDirection", newDirection);
    setProjectSortDirection(newDirection);
  };

  useEffect(() => {
    if (!projects || projects.length === 0) {
      fetchProjects();
    }
    // Fetch teams on component mount
    //@ts-ignore
    store.dispatch(getTeams());
  }, []);

  const { openModal, setMode } = useBaseModalContext();

  const handleCreateNewProject = () => {
    const _projectsCount = (projects.filter((_project: any) => !_project.isTemplate)).length;

    if ((!isFree) || (_projectsCount < 1) || isAdmin) {
      openModal({ mode: "NEW_PROJECT", data: {} });
    } else {
      openModal({ mode: "SUBSCRIBE", data: {}, trigger: "PROJECT" });
    }
  };

  const appContext = useAppContext();

  return (
    <main className="w-full h-screen flex flex-col">
      <Navbar />
      {/* <aside 
        className="flex flex-col w-[300px] border-r border-gray-200 py-4 xl:block bg-neutral-800 border-r border-r-neutral-600">
        <div className="flex flex-col flex-1 gap-2 mb-4">
          
          <div className={"px-4 relative w-full"}>
            <input type={"text"}
                   className={"w-full h-[32px] pl-8 rounded border-none bg-neutral-700 text-neutral-100 text-sm placeholder-neutral-400 bg-opacity-50"}
                   placeholder={"Search for a story"} />
            <div className={"absolute w-[20px] h-[20px] top-1.5 left-6"}>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-neutral-400"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <button className={"w-full flex gap-x-2 px-6 py-2 items-center text-neutral-100 bg-neutral-700"}>
              <ClockIcon className={"w-[16px] h-[16px]"} />
              <p className={"text-sm"}>Recents</p>
            </button>
          </div>
          <div className={"w-full h-[1px] bg-neutral-700 my-1"} />
          <div className={"flex flex-col flex-1"}>
            <div>
              <h4 className={"px-4 font-bold text-xs text-neutral-100"}>Favourites</h4>
              <p className={"px-4 py-2 text-xs text-neutral-400"}>No favourites are selected</p>
            </div>
            <div className={"w-full h-[1px] bg-neutral-700 my-1"} />
            <div>
              <div className={"team-selector px-4  my-2 flex items-align justify-between"}>
                <h4 className={" font-bold text-xs text-neutral-100"}>Teams</h4>
                <button
                  className={"bg-white bg-opacity-5 border border-neutral-300 rounded w-[16px] h-[16px] flex items-center justify-center hover:bg-opacity-20"}
                  onClick={() =>
                    store.dispatch(
                      // @ts-ignore
                      setModal({ modal: ENUM_MODAL_TYPE.CREATE_TEAM, data: null })
                    )
                  }><PlusIcon className={"w-[12px] h-[12px] text-neutral-100"} /></button>
              </div>
              <div className={"flex flex-col"}>
                {teams?.map((team: any) =>
                  <button
                    key={team?.id}
                    className={`text-left text-xs text-xs px-4 py-1 hover:bg-neutral-600 ${account.recentTeam === team?.id ? "text-sky-500" : "text-neutral-400"}`}
                    onClick={() => setTeamRequest({ teamId: team?.id })}>{team.label}</button>)}
              </div>
            </div>
          </div>

          <p className="text-xl font-bold">Private</p>
          <button
          onClick={() => navigate("teams")}
          type="button"
          className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
          <ArrowRightOnRectangleIcon
          // {/*    className="h-4 w-4 shrink-0"
          // {/*    aria-hidden="true"
          // {/*  />
          // {/*</button>
        // </div>
        

        // {/*<div className="flex flex-col gap-2">
        // {/*  {teams?.map((team: any) => (
        // {/*    <Team
        // {/*      key={team?.id}
        // {/*      team={team}
        // {/*      onClick={({ value }: { value: string }) =>
        // {/*        setTeamRequest({ teamId: value })
        // {/*      }
        // {/*      isSelectable
        // {/*    />
        // {/*  ))}
        // {/*</div>
      </aside>*/}
      {(appContext.isLoadingProjectsRequestInProgress || isTemplateLoading || (appContext.isLoadingProjectsRequestInProgress && isFirstLogin)) && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-50">
          <CustomLoadingSpinner size={60} />
          {isTemplateLoading ? (
            <p className="mt-4 text-white text-lg" dangerouslySetInnerHTML={{ __html: "Setting up a demo project.<br />This takes about 2 min." }}></p>
          ) : (appContext.isLoadingProjectsRequestInProgress && isFirstLogin) ? (
            <p className="mt-4 text-white text-lg">We are getting your account ready.<br />Hang on tight.</p>
          ) : appContext.isLoadingProjectsRequestInProgress ? (
            <p className="mt-4 text-white text-lg">Just a moment, we are fetching your stories.</p>
          ) : null}
        </div>
      )}
      <div className="mx-2 mt-10 mb-2 sm:m-10 h-full flex flex-col gap-y-4 overflow-auto">
        <Templates isOpen={false} />
        <div className={"p-4 sm:p-10 h-full flex flex-col gap-y-4 "}>
          <div className={"flex justify-between "}>
            <span className="flex items-center gap-x-4">
              <h3 className="text-sm text-neutral-300">Stories:</h3>
              <span className="ml-2 text-white flex items-center gap-x-2">
                <span onClick={toggleProjectSortCriteria} className="cursor-pointer text-sm text-neutral-300">
                  {projectSortCriteria}
                </span>
                <span onClick={toggleProjectSortDirection} className="ml-0 cursor-pointer">
                  <ChevronDownIcon
                    className={`w-4 h-4 inline text-neutral-300 mb-[4px] ${projectSortDirection === "descending" ? "rotate-0" : "rotate-180"}`} />
                </span>
              </span>
            </span>
            <div className="hidden sm:flex project-view-change gap-x-2">
              <button className={`p-1 rounded ${viewMode === "GRID" && "bg-neutral-700"}`}
                onClick={() => setViewMode("GRID")}>
                <Squares2X2Icon className={"w-[16px] h-[16px] text-neutral-300"} />

              </button>
              <button className={`p-1 rounded ${viewMode === "LIST" && "bg-neutral-700"}`}
                onClick={() => setViewMode("LIST")}>
                <Bars3Icon className={"w-[16px] h-[16px] text-neutral-300"} />
              </button>
            </div>
          </div>
          <div id="projects-container"
            className="flex flex-col rounded-xl sm:rounded-lg px-5 sm:px-10 pt-10 flex-grow pb-20 bg-neutral-800 ">
            <div
              className={`${viewMode === "GRID"
                ? "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 auto-rows-fr"
                : "flex flex-col gap-y-2"
                } w-full`}
            >
              {projects && projects.map((story: any) => {
                const isMostRecent = story.id === mostRecentProjectId;
                const isTemplateOrDemo = story?.isTemplate;

                const isGreyedOut = isFree && !isMostRecent && !isTemplateOrDemo && !isAdmin;

                return (
                  <div
                    key={story?.id}
                    className={`relative group cursor-pointer w-full ${viewMode !== "GRID" ? "max-h-[100px]" : ""
                      } ${isGreyedOut ? "greyed-out-class" : ""
                      }`}
                    onClick={() => {
                      if (isGreyedOut) {
                        openModal({ mode: "SUBSCRIBE", data: {}, trigger: "PROJECT" });
                      } else {
                        if (story?.isTemplate && !localStorage.getItem(`app-tour`)) {
                          navigate(`/stories/${story.id}/export`, { state: { viewMode: "preview" } });
                        } else {
                          navigate(`/stories/${story.id}/board`);
                        }
                      }
                    }}
                  >
                    <Project story={story} viewMode={viewMode} restricted={isGreyedOut} />
                    {
                      isGreyedOut && (
                        <div className="absolute top-0 left-0 w-full h-full z-2 bg-neutral-800 rounded-lg opacity-50 backdrop-blur"></div>
                      )
                    }
                  </div>
                );
              })}

              {!loading && (
                <button
                  className={`create-project-button rounded-lg border-2 border-dashed border-neutral-500 hover:bg-neutral-700 flex justify-center items-center cursor-pointer disabled:cursor-default disabled:opacity-25 disabled:hover:bg-neutral-800 ${viewMode === "GRID" ? `col-span-1 w-full h-full ${projects.length === 0 && "aspect-[16/11]"}` : "w-full h-[80px]"
                    }`}
                  onClick={handleCreateNewProject}
                >
                  <span className="text-4xl text-gray-500">+</span>
                </button>
              )}
            </div>
          </div>


        </div>
      </div>

    </main>
  );
}

export default Projects;

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { TModalMode } from "./BaseModal";
import { TCharacter } from "../../character/Character";

type ModalData = {
  data: any;
  trigger?: string;
  images?: any[];
  shot?: any;
  scenes?: any[];
  mode?: string;
  character?: any;
  status?: string;
  function?: Function;
  redirect?: Function;
  title?: string;
  description?: string;
  story?: any;
  renameStory?: Function;
  downgrade?: Function;
};

type BaseModalContext = {
  isOpen: boolean;
  setIsOpen: Function;
  mode: TModalMode;
  setMode: Function;
  onCancel: Function;
  setOnCancel: React.Dispatch<React.SetStateAction<Function>>;
  onSave: Function;
  setOnSave: React.Dispatch<React.SetStateAction<Function>>;
  alreadyUsedCharacterImages: string[];
  setAlreadyUsedCharacterImages: React.Dispatch<React.SetStateAction<string[]>>;
  modalData: ModalData;
  setModalData: Function;
  openModal: Function;
  closeModal: Function;
  youtubeId: String;
  videoUrl: string | null;
  setVideoUrl: (url: string | null) => void;
  setYoutubeId: any;
  styleClientIndex: number;
  setStyleClientIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedCharacter: TCharacter | null;
  setSelectedCharacter: React.Dispatch<React.SetStateAction<TCharacter | null>>;
};

const BaseModalContext = createContext<BaseModalContext | undefined>(undefined);
const initialModalData: ModalData = {
  data: null,
  trigger: undefined,
  images: undefined,
  shot: undefined,
  scenes: undefined,
  mode: undefined,
  character: undefined,
  status: undefined,
  function: undefined,
  redirect: undefined,
  title: undefined,
  description: undefined,
  story: undefined,
  renameStory: undefined,
  downgrade: undefined,
};

type BaseModalProviderProps = {
  children: ReactNode;
};

export const useBaseModalContext = (): BaseModalContext => {
  return useContext(BaseModalContext) as BaseModalContext;
};

export const BaseModalContextProvider: React.FC<BaseModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>(initialModalData);
  const [mode, setMode] = useState<TModalMode>("SUBSCRIBE");
  const [onCancel, setOnCancel] = useState<Function>(() => () => { });
  const [onSave, setOnSave] = useState<Function>(() => () => { });
  const [youtubeId, setYoutubeId] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [styleClientIndex, setStyleClientIndex] = useState<number>(1);
  const [alreadyUsedCharacterImages, setAlreadyUsedCharacterImages] = useState<string[]>([]);
  const [selectedCharacter, setSelectedCharacter] = useState<TCharacter | null>(null);

  const openModal = ({ mode, data, trigger }: { mode: TModalMode, data: ModalData, trigger?: string }) => {
    console.log('openModal data:', data);
    setMode(mode);
    setIsOpen(true);
    setModalData({ ...data, trigger });
    console.log('updated modalData:', { ...data, trigger });
  };

  const closeModal = () => {
    setMode(undefined);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("BaseModal mode updated in Context to: ", mode);
    console.log("typeof mode:", typeof mode);
    console.log("mode === undefined:", mode === undefined);
    if (mode === undefined) {
      console.log("Setting selectedCharacter to null");
      setSelectedCharacter(null);
    }
  }, [mode]);  

  useEffect(() => {
    console.log("selectedCharacter is now: ", selectedCharacter);
  }, [selectedCharacter]);

  return (
    <BaseModalContext.Provider value={{
      onCancel,
      setOnCancel,
      onSave,
      setOnSave,
      alreadyUsedCharacterImages,
      setAlreadyUsedCharacterImages,
      setIsOpen,
      isOpen,
      mode,
      setMode,
      modalData,
      setModalData,
      openModal,
      closeModal,
      youtubeId,
      setYoutubeId,
      videoUrl,
      setVideoUrl,
      styleClientIndex,
      setStyleClientIndex,
      selectedCharacter,
      setSelectedCharacter,
    }}>
      {children}
    </BaseModalContext.Provider>
  );
};

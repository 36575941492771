import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const HotjarTracking = () => {
  const { userId, availableCredits } = useSelector((state: any) => ({
    userId: state.app.account?.id,
    availableCredits: state.app.account?.stats?.availableCredits
  }));

  useEffect(() => {
    if (userId) {
      (function(h, o, t, j) {
        h.hj = h.hj || function() {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
        h._hjSettings = { hjid: 3719651, hjsv: 6 };

        // Get the head element
        const a = o.getElementsByTagName("head")[0] as HTMLHeadElement;

        // Create the script element
        const r = o.createElement("script") as HTMLScriptElement;
        r.async = true;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;

        // Append the script to the head element
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

      // Retrieve login count from localStorage
      const loginCount = localStorage.getItem('loginCount') || '0';

      // Send userId, availableCredits, and loginCount to Hotjar
      if (window.hj) {
        window.hj('identify', userId, {
          'Available Credits': availableCredits,
          'Login Count': parseInt(loginCount, 10), // Convert to number and pass
        });
      }
    }
  }, [userId, availableCredits]);

  return null;
};

export default HotjarTracking;

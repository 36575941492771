import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react';
import { StarIcon, TrashIcon } from '@heroicons/react/20/solid';
import { storyBoardsApiClient } from '../../../core/config/api';
import { TCustomCharacter } from '../character/Character';
import { characters } from '../../../assets/characters/characters';
import { useBaseModalContext } from '../modals/base-modal/BaseModalContext';
import { TCharacter } from '../character/Character';
import { TModalMode } from '../modals/base-modal/BaseModal';
import { useAppContext } from '../../../core/utils/AppContext';
import { useTrialEligibility } from '../../../core/utils/useTrialEligibility';
import UniversalTippy from '../universal-tippy/UniversalTippy';
import { useImageValidator } from '../../../core/utils/validateImage';
import { sendEventToGoogleTagManager } from '../../../core/utils/googleTagManager';
import useUserPlan from '../../../core/utils/creditCost';


const freeTierCharacterIds = ['0072', '0173', '0024', '0160'];
const characterApi = storyBoardsApiClient.character; 

interface CharacterSelectorProps {
  onCharacterCreated: (character: TCharacter) => void;
  onCharacterUpdated: (character: TCharacter) => void;
  onEditCharacter: (isEditing: boolean) => void;
  onClose?: () => void;
  onModeChange: (newMode: TModalMode) => void;
}

interface Ethnicity {
  label: EthnicityLabel;
  value: EthnicityValue[];
}

interface ActiveFilters {
  age: string[];
  gender: string[];
  ethnicity: string[];
}

interface FilterButtonProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

type AgeRange = "10's" | "20's" | "30's" | "40's" | "50's" | "60's" | "70's";
type Gender = "male" | "female";
type EthnicityLabel = "Caucasian" | "Asian" | "Black" | "South American" | "Middle Eastern" | "South Asian";
type EthnicityValue = "european" | "nordic" | "white" | "asian" | "black" | "south_american" | "middle_eastern" | "indian";

const ageRanges: AgeRange[] = ["10's", "20's", "30's", "40's", "50's", "60's", "70's"];
const genders: Gender[] = ["male", "female"];
const ethnicities: Ethnicity[] = [
  { label: "Caucasian", value: ["european", "nordic", "white"] },
  { label: "Asian", value: ["asian"] },
  { label: "Black", value: ["black"] },
  { label: "South American", value: ["south_american"] },
  { label: "Middle Eastern", value: ["middle_eastern"] },
  { label: "South Asian", value: ["indian"] },
];

const FilterButton: React.FC<FilterButtonProps> = ({ label, active, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 rounded-md text-xs font-small transition-colors duration-200 ease-in-out ${active
      ? "bg-neutral-600 border border-neutral-600 text-white"
      : "bg-none text-neutral-600 border border-neutral-600"
      }`}
  >
    {label}
  </button>
);

const CharacterSelector: React.FC<CharacterSelectorProps> = ({
  onCharacterCreated,
  onCharacterUpdated,
  onEditCharacter,
  onModeChange,
}) => {
  const baseModalContext = useBaseModalContext();
  const [allCharacters, setAllCharacters] = useState<any[]>([...characters]);
  const [filteredCharacters, setFilteredCharacters] = useState<any[]>([]);

  const [form, setForm] = useState<{ image?: string; name?: string; description?: string }>({
    image: baseModalContext.selectedCharacter?.image ?? '',
    name: baseModalContext.selectedCharacter?.name ?? '',
    description: baseModalContext.selectedCharacter?.description ?? '',
  });  

  const { isFree, isPlan, costs } = useUserPlan();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showOnlyCurrentSubscriptionSwitch, setShowOnlyCurrentSubscriptionSwitch] = useState(true);

  const { storyId } = useParams<{ storyId: string }>();
  const { mode } = useBaseModalContext();

  const { validateAndProcessImage, error: imageError } = useImageValidator();

  const [loadingCharacters, setLoadingCharacters] = useState<Set<string>>(new Set());

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    age: [],
    gender: [],
    ethnicity: [],
  });

  const toggleFilter = useCallback((category: keyof ActiveFilters, value: string) => {
    setActiveFilters((prev) => {
      const newFilters = { ...prev };
      if (newFilters[category].includes(value)) {
        newFilters[category] = newFilters[category].filter((v) => v !== value);
      } else {
        newFilters[category] = [...newFilters[category], value];
      }
      return newFilters;
    });
  }, []);

  const filterCharacters = useCallback(() => {
    let filteredChars = allCharacters.filter((character) => {
      const age = parseInt(character.age, 10);
      const ageRange = `${Math.floor(age / 10) * 10}'s`;
  
      const matchesAge = activeFilters.age.length === 0 || activeFilters.age.includes(ageRange);
      const matchesGender = activeFilters.gender.length === 0 || activeFilters.gender.includes(character.sex);
      const matchesEthnicity = activeFilters.ethnicity.length === 0 ||
        activeFilters.ethnicity.some(ethnicity =>
          ethnicities.find(e => e.label === ethnicity)?.value.includes(character.ethnicity as EthnicityValue)
        );
  
      return matchesAge && matchesGender && matchesEthnicity;
    });
  
    if (isFree) {
      if (!showOnlyCurrentSubscriptionSwitch) {
        // When switch is OFF: Show only free-tier characters
        filteredChars = filteredChars.filter((character) => freeTierCharacterIds.includes(character?.id) && character.sex);
      }
      // When switch is ON: Show all characters (no additional filtering)
    }
    // For paid users, no additional filtering is needed
  
    setFilteredCharacters(filteredChars);
  }, [activeFilters, allCharacters, isFree, showOnlyCurrentSubscriptionSwitch]);

  useEffect(() => {
    filterCharacters();
  }, [filterCharacters, showOnlyCurrentSubscriptionSwitch]);  

  // hook to update the form when the modalData changes
  useEffect(() => {
    if (mode === 'UPDATE' || mode === 'AI_FOUND') {
      setForm({
        image: baseModalContext.selectedCharacter?.image ?? '',
        name: baseModalContext.selectedCharacter?.name ?? '',
        description: baseModalContext.selectedCharacter?.description ?? '',
      });
    }
  }, [baseModalContext.selectedCharacter, mode]);  

  useEffect(() => {
    const fetchCustomCharacters = async () => {
      try {
        const customCharacters = await characterApi.findAllCustomCharacters();
        setAllCharacters([...customCharacters.slice().reverse(), ...characters]);
      } catch (error) {
        console.error('Error fetching custom characters:', error);
      }
    };
    fetchCustomCharacters();
  }, [isFree]);

  // useEffect(() => {
  //   console.log("allCharacters: ",allCharacters)
  // }, [allCharacters]);
  

  const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setForm(prevForm => ({
      ...prevForm,
      name: value,
    }));
    baseModalContext.setSelectedCharacter(prevChar => ({
      ...prevChar,
      name: value,
    }) as TCharacter);
  };
  
  const handleDescriptionInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setForm(prevForm => ({
      ...prevForm,
      description: value,
    }));
    baseModalContext.setSelectedCharacter(prevChar => ({
      ...prevChar,
      description: value,
    }) as TCharacter);
  };
  

  const handleCreateCharacterClick = async () => {
    console.log("Create new character clicked");
    
    // Open a file picker
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
  
    input.onchange = async (event) => {
      try {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
          console.log("Starting Image validation and processing");
          const validatedFile = await validateAndProcessImage(file);
          if (!validatedFile) {
            toast.error(imageError);
            console.error('Image validation failed:', imageError);
            return;
          }
          try {
            // Call the API to create a custom character
            const response = await characterApi.createCustomCharacter(validatedFile);
            console.log('Custom character created:', response.data);
            
            // Correctly assign newCharacter
            const newCharacter = response.data.data; // TCustomCharacter
  
            // Update state
            setAllCharacters(prevCharacters => [newCharacter, ...prevCharacters]);
  
            // Optionally, call onCharacterCreated if needed
            // onCharacterCreated(newCharacter);
  
            // Show success message
            // toast.success('Custom character created successfully');
            sendEventToGoogleTagManager("create_custom_character_success", {});
            
          } catch (error) {
            console.error('Error creating custom character:', error);
          }
        }
      } catch (error) {
        console.error('Error in input.onchange:', error);
        toast.error(error as string);
      }
    };
    input.click();
  };

  const handleDeleteCharacter = async (characterId: string) => {
    console.log(`Deleting character with id: ${characterId}`);
  
    try {
      // Add the character ID to the loading state
      setLoadingCharacters(prev => new Set(prev).add(characterId));
  
      // Optimistically remove the character from the state
      setAllCharacters(prevCharacters => prevCharacters.filter(char => char.id !== characterId));
  
      // Make the API call to delete the character
      await characterApi.deleteCustomCharacter({ customCharacterId: characterId }); // Updated API call
  
      // Optionally, you can call onCharacterUpdated or any other callback
      if (onCharacterUpdated) {
        onCharacterUpdated({ id: characterId, /* other properties if needed */ } as TCharacter);
      }
  
      // Show a success toast
      // toast.success('Character deleted successfully');
    } catch (error) {
      console.error(`Error deleting character with id ${characterId}:`, error);
      toast.error('Failed to delete character');
  
      // Optionally, re-add the character to the state if deletion fails
      // You might need to refetch the characters or handle this differently
      // For simplicity, let's refetch all characters
      try {
        const customCharacters = await characterApi.findAllCustomCharacters();
        setAllCharacters([...customCharacters.slice().reverse(), ...characters]);
      } catch (fetchError) {
        console.error('Error refetching characters after failed deletion:', fetchError);
      }
    } finally {
      // Remove the character ID from the loading state
      setLoadingCharacters(prev => {
        const newSet = new Set(prev);
        newSet.delete(characterId);
        return newSet;
      });
    }
  };
  
  
  return (
    <>
      <div className='modal-container w-full flex flex-col bg-neutral-300'>
        {/* Header Section */}
        <div className='sticky top-0 p-4 z-30 bg-neutral-300'>
          <div className='flex flex-col space-y-4'>
            {/* Name and Description Inputs, and Subscription Switch */}
            <div className="w-full grid grid-cols-3 items-center gap-4">
              {/* First Column */}
              <div className="flex items-center justify-center">
                {(baseModalContext.mode === "NEW" || baseModalContext.mode === "UPDATE") && (
                  /* Name Input Field */
                  <div className="flex flex-col">
                    <label htmlFor="name" className="text-md font-medium text-gray-700">Name</label>
                    <input
                      type='text'
                      name='name'
                      id='name'
                      placeholder='Character name'
                      className='w-60 h-10 uppercase bg-neutral-300 rounded font-semibold text-neutral-900 placeholder-neutral-500 focus:ring-0 mt-2'
                      value={form.name}
                      onChange={handleNameInputChange}
                    />
                  </div>
                )}
                {baseModalContext.mode === "AI_FOUND" && isFree && (
                  /* Toggle Switch for AI_FOUND Mode (Unsubscribed Users) */
                  <div className='flex items-center'>
                    <Switch
                      checked={showOnlyCurrentSubscriptionSwitch}
                      onChange={setShowOnlyCurrentSubscriptionSwitch}
                      className={`${showOnlyCurrentSubscriptionSwitch ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full mr-2`}
                    >
                      <span className={`${showOnlyCurrentSubscriptionSwitch ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                    </Switch>
                    {showOnlyCurrentSubscriptionSwitch ? (
                      <StarIcon className='w-5 h-5 text-yellow-500' />
                    ) : (
                      <StarIcon className='w-5 h-5 text-black' stroke="currentColor" fill="none" />
                    )}
                    <span className='ml-2 text-sm text-gray-600 whitespace-nowrap'>
                      {showOnlyCurrentSubscriptionSwitch ? 'All' : 'In your plan'}
                    </span>
                  </div>
                )}
              </div>

              {/* Second Column */}
              <div className="flex items-center justify-center">
                {(baseModalContext.mode === "NEW" || baseModalContext.mode === "UPDATE") && (
                  /* Description Input Field */
                  <div className="flex flex-col">
                    <label htmlFor="description" className="text-nd font-medium text-gray-700">Description</label>
                    <input
                      type='text'
                      name='description'
                      id='description'
                      placeholder='Character description'
                      className='w-60 h-10 bg-neutral-300 rounded font-semibold text-neutral-900 placeholder-neutral-500 focus:ring-0 mt-2'
                      value={form.description}
                      onChange={handleDescriptionInputChange}
                    />
                  </div>
                )}
              </div>

              {/* Third Column */}
              <div id="third_column" className="flex items-center justify-center">
                {(baseModalContext.mode === "NEW" || baseModalContext.mode === "UPDATE") && isFree && (
                  /* Subscription Switch */
                  <div className='flex items-center'>
                    <Switch
                      checked={showOnlyCurrentSubscriptionSwitch}
                      onChange={setShowOnlyCurrentSubscriptionSwitch}
                      className={`${showOnlyCurrentSubscriptionSwitch ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full mr-2`}
                    >
                      <span className={`${showOnlyCurrentSubscriptionSwitch ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                    </Switch>
                    {showOnlyCurrentSubscriptionSwitch ? (
                      <StarIcon className='w-5 h-5 text-yellow-500' />
                    ) : (
                      <StarIcon className='w-5 h-5 text-black' stroke="currentColor" fill="none" />
                    )}
                    <span className='ml-2 text-sm text-gray-600 whitespace-nowrap'>
                      {showOnlyCurrentSubscriptionSwitch ? 'All' : 'In your plan'}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {/* Filter Buttons */}
            <div className="flex flex-wrap gap-2">
              {genders.map((gender) => (
                <FilterButton
                  key={gender}
                  label={gender}
                  active={activeFilters.gender.includes(gender)}
                  onClick={() => toggleFilter("gender", gender)}
                />
              ))}
              {ageRanges.map((age) => (
                <FilterButton
                  key={age}
                  label={age}
                  active={activeFilters.age.includes(age)}
                  onClick={() => toggleFilter("age", age)}
                />
              ))}
              {ethnicities.map((ethnicity) => (
                <FilterButton
                  key={ethnicity.label}
                  label={ethnicity.label}
                  active={activeFilters.ethnicity.includes(ethnicity.label)}
                  onClick={() => toggleFilter("ethnicity", ethnicity.label)}
                />
              ))}
            </div>
          </div>
        </div>


        {/* Main Content Section */}
        <div className='flex-1 overflow-y-auto p-4'>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4'>
            {/* Ghost entry for creating new character */}
            {!isFree ? (
              <UniversalTippy content="Add your own character.">
                <div
                  onClick={handleCreateCharacterClick}
                  className="relative w-full max-w-[200px] aspect-square flex items-center justify-center cursor-pointer"
                >
                  <div className="w-full h-full border-2 border-dashed border-neutral-500 rounded-lg flex items-center justify-center hover:bg-neutral-300 transition-colors duration-200">
                    <span className="text-4xl text-neutral-500">+</span>
                  </div>
                </div>
              </UniversalTippy>
            ) : (
              <UniversalTippy content="Upgrade to add custom characters.">
                <div className="relative w-full max-w-[200px] aspect-square flex items-center justify-center cursor-not-allowed">
                  <div className="w-full h-full border-2 border-dashed border-neutral-300 rounded-lg flex items-center justify-center bg-neutral-200">
                    <span className="text-4xl text-neutral-400">+</span>
                  </div>
                </div>
              </UniversalTippy>
            )}

            {/* Existing character grid items */}
            {(!isFree || showOnlyCurrentSubscriptionSwitch
              ? filteredCharacters
              : filteredCharacters.filter((character) => freeTierCharacterIds.includes(character?.id) && character.sex)
            )
              .filter((character) => baseModalContext.mode === 'NEW' ? !baseModalContext?.alreadyUsedCharacterImages.includes(character.img) : true)
              .map((character) => (
                <div
                  key={character?.id}
                  onClick={() => {
                    const newImage = character.img || character.image || '';
                    console.log('New actor selected:', newImage);
                  
                    const hasBodyPrompt = Boolean(character.body_prompt);
                    const newDescription = hasBodyPrompt ? character.body_prompt : (character.description || '');
                  
                    if (baseModalContext.mode === 'NEW') {
                      // Update only the image in the form state
                      setForm(prevForm => ({
                        ...prevForm,
                        image: newImage,
                      }));
                  
                      baseModalContext.setSelectedCharacter(prevChar => {
                        if (!prevChar) {
                          // Initialize selectedCharacter with current form values and new image
                          return {
                            id: character.id,
                            name: form.name || '',
                            description: form.description || '',
                            image: newImage,
                            // Include other required properties of TCharacter here
                          } as TCharacter;
                        }
                  
                        // Update only the image, preserving name and description
                        return {
                          ...prevChar,
                          image: newImage,
                        } as TCharacter;
                      });
                  
                    } else {
                      // In other modes, update image and conditionally update description
                      baseModalContext.setSelectedCharacter(prevChar => {
                        if (!prevChar) {
                          // Initialize selectedCharacter with character properties
                          return {
                            id: character.id,
                            name: character.name || '',
                            description: newDescription,
                            image: newImage,
                            // Include other required properties of TCharacter here
                          } as TCharacter;
                        }
                  
                        // Update image and conditionally update description
                        return {
                          ...prevChar,
                          image: newImage,
                          description: hasBodyPrompt ? character.body_prompt : prevChar.description,
                        } as TCharacter;
                      });
                    }
                  }}
                  
                  className='relative w-full max-w-[200px] aspect-square flex items-center justify-center group hover:bg-neutral-100'
                >
                  {/* Character Image */}
                  <img
                    className={`cursor-pointer rounded object-cover w-full h-full ${
                      (baseModalContext.mode === 'NEW' && (character.img === form.image || character.image === form.image)) ||
                      (baseModalContext.mode !== 'NEW' && (character.img === baseModalContext.selectedCharacter?.image || character.image === baseModalContext.selectedCharacter?.image))
                        ? 'border-4 border-blue-500'
                        : ''
                    }`}
                    src={character.image || character.img}
                    alt=''
                  />
              
                  {/* Overlay for Non-Free Characters */}
                  {(!freeTierCharacterIds.includes(character?.id) || !character.sex) && isFree && (
                    <div className='absolute w-full h-full bg-neutral-600 bg-opacity-50 rounded'>
                      <div className='absolute bottom-0 right-0 text-white px-2 py-1 rounded rounded-tr-md'>
                        <StarIcon className='w-[27px] text-yellow-500' />
                      </div>
                    </div>
                  )}
              
                  {/* Trash Icon for Custom Characters */}
                  {!character.sex && (
                    <button
                      className="absolute top-2 right-2 bg-red-100 rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the parent onClick
                        handleDeleteCharacter(character.id);
                      }}
                    >
                      <TrashIcon className="text-red-500 w-4 h-4" />
                    </button>
                  )}
                </div>
              ))}  
          </div>
        </div>
      </div>

      {errorMessage && (
        <div className='text-sm text-red-500 flex justify-end'>{errorMessage}</div>
      )}
    </>
  );
};

export default CharacterSelector;

import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Character from "../../character";
import { storyBoardsApiClient } from "../../../../core/config/api";
import { TCharacter } from "../../character/Character";
import CharacterSelector from "../../character-selector";
import Carousel from "../../carousel/carousel";
import { castSteps, foundSteps, styleSteps } from "../../tour/TourSteps";
import { useTour } from "@reactour/tour";
import { useBaseModalContext } from "./BaseModalContext";
import { StyleSelector } from "../../style-selector/StyleSelector";
import useAuth from "../../../../core/hooks/auth";
import Subscribe from "../subscribe";
import CreateProject from "../projects/create";
import RenameProject from "../system/rename-project/RenameProject";
import EditCharacter from "../edit-character/EditCharacter";
import DowngradeModal from "../../subscription/DowngradeModal";
import ConfirmDelete from "../system/confirm-delete";
import SubscriptionResult from "../subscription-result";
import FirstLoginEmailConfirm from "../system/first-login-email-confirm/FirstLoginEmailConfirm";
import GetStarted from "../system/get-started/GetStarted";
import VideoModal from "../intro-video/VideoModal";
import CastUI from "./castui/castUI";
import { useAppContext } from "../../../../core/utils/AppContext";
import VideoDownloadModal from "./videodownload/VideoDownloadModal";
import { sendEventToGoogleTagManager } from "../../../../core/utils/googleTagManager";

export type TModalMode = "NEW_PROJECT" | "CAST" | "NEW" | "UPDATE" | "AI_FOUND" | "STYLE" | "VIEWER" | "SUBSCRIBE" | "IMAGE_VIEWER" | "CONFIRM" | "CREATE_TEAM" | "EDIT_CHARACTER" | "INVITE_TEAM_MEMBER" | "FIRST_LOGIN_EMAIL_CONFIRM" | "RENAME_PROJECT" | "CONFIRM_DOWNGRADE" | "SUBSCRIPTION_RESULT" | "GET_STARTED" | "VIDEO" | "VIDEO_DOWNLOAD" | undefined;

interface CastModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode: TModalMode;
}

function BaseModal({ isOpen,  onClose, mode: propMode }: CastModalProps) {
  const { storyId } = useParams();

  // State hooks
  const { user, charactersList, setCharactersList } = useAppContext();
  const [mode, setMode] = useState(propMode);
  const [selectedStyle, setSelectedStyle] = useState<string>("");
  const { setSteps, setIsOpen, currentStep, setCurrentStep } = useTour();

  const baseModalContext = useBaseModalContext();
  
  const [activeButton, setActiveButton] = useState<string>("actors");

  const [isEditingCharacter, setIsEditingCharacter] = useState(false)

  const { selectedCharacter } = baseModalContext;
  const [isEditingOutfit, setIsEditingOutfit] = useState(false)

  const { setOnSave, closeModal, modalData } = useBaseModalContext();

  const [data]: any = useAuth();

  const ref: any = useRef(null);

  const freeTierCharacterIds = ['0072', '0173', '0024', '0160'];

  useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        baseModalContext.closeModal();
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);;

  useEffect(() => {
    setMode(propMode);
  }, [propMode]);

  useEffect(() => {
    if (isOpen && ["CAST", "EDIT"].includes(propMode ?? "")) {
      retrieveCharactersRequest();
    }
  }, [isOpen]);

  useEffect(() => {
    if (setSteps) {
      if (propMode === "CAST") {
        setSteps(castSteps);
        console.log("setting tour CAST steps");
      } else if (mode === "STYLE") {
        setSteps(styleSteps);
        console.log("setting tour STYLE steps");
      } else if (mode === "AI_FOUND") {
        setSteps(foundSteps);
        console.log("FOUND");
      } else if (mode === "VIEWER") {
        console.log("VIEWER");
      } else if (mode === "VIDEO") {
        console.log("VIDEO");
      }
    }
  }, [mode]);
  
  useEffect(() => {
    if (!isOpen) {
      setActiveButton("actors");
    }
  }, [isOpen]);

  const handleModeChange = (newMode: any) => {
    console.log(`handleModeChange called, setting mode to ${newMode}`);
    baseModalContext.setMode(newMode);
  };

  const handleCharacterCreated = (newCharacter: TCharacter) => {
    setCharactersList(prevCharacters => [...prevCharacters, newCharacter]);
    // setSelectedCharacter({ id: "", name: "", description: "", image: "", scene: [] });
    baseModalContext.setAlreadyUsedCharacterImages([...baseModalContext?.alreadyUsedCharacterImages, newCharacter?.image]);
  };

  const updateCharacterRequest = async () => {
    const currentSelectedCharacter = baseModalContext.selectedCharacter;
    const characterToUpdate = baseModalContext.mode === 'UPDATE' ? currentSelectedCharacter : modalData.character;
    console.log("Current selected character:", currentSelectedCharacter);
    console.log("Character to update:", characterToUpdate);
  
    const payload: any = {
      id: characterToUpdate?.id ?? '',
      storyId: storyId ?? '',
      name: characterToUpdate?.name,
      description: characterToUpdate?.description ?? '',
      image: currentSelectedCharacter?.image,
    };
    console.log("Updating character with payload:", payload);
  
    try {
      const result = await storyBoardsApiClient.character.updateCharacter(payload);
  
      console.log("API response:", result);
  
      if (result.status === 200) {
        console.log("Character updated successfully:", result.data);
  
        setCharactersList(prevList =>
          prevList.map(char =>
            char.id === result.data.id
              ? { ...char, ...result.data }
              : char
          )
        );
  
        baseModalContext.setSelectedCharacter(result.data);
        baseModalContext.closeModal();
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error('Error in API call:', error);
      toast.error('Something went wrong');
    }
  
    console.log("updateCharacterRequest finished");
  };

  const createCharacterRequest = async () => { 
    console.log('Creating character...', selectedCharacter);
  
    if (!selectedCharacter?.image) {
      toast.error('You must select an actor first');
      return;
    }
  
    if (
      !freeTierCharacterIds.includes(selectedCharacter.image.slice(0, 4) ?? '0000') &&
      !data?.data?.subscription?.id
    ) {
      toast.info('Please subscribe to use this actor');
      return;
    }
  
    if (!selectedCharacter.name || selectedCharacter.name.length < 2) {
      toast.error('Minimum 2 letters required for the character name!');
      return;
    }

    if (!selectedCharacter.description || selectedCharacter.description.length < 2) {
      toast.error('missing character description');
      return;
    }
  
    try {
      const payload: any = {
        storyId: storyId ?? '',
        name: selectedCharacter.name,
        description: selectedCharacter.description ?? '',
        image: selectedCharacter.image,
      };
      const result = await storyBoardsApiClient.character.createCharacter(payload);
  
      if (result.status === 201) {
        console.log("201");
        sendEventToGoogleTagManager('create_character_success', {
          category: 'Character Management',
          action: 'Create',
          label: 'Success',
          character_image: result.data.image,
        });
        handleCharacterCreated(result.data);
        setCharactersList(prevList => [...prevList, result.data]);
        closeModal();
      } else {
        console.log("non 201 response");
        toast.error('Something went wrong');
      }

      baseModalContext.setSelectedCharacter(null);
    } catch (error) {
      console.error(error);
      toast.error('Error: Something went wrong');
      sendEventToGoogleTagManager('create_character_fail', {
        category: 'Character Management',
        action: 'Create',
        label: 'Failure',
        error_message: error || 'Unknown error',
      });
    }
  };

  const handleSave = useCallback(async () => {
    if (baseModalContext.mode === 'UPDATE' || baseModalContext.mode === 'AI_FOUND') {
      await updateCharacterRequest();
      console.log('updateCharacterRequest completed');
      if (baseModalContext.mode === 'UPDATE') {
        handleModeChange('CAST');
      } else {
        closeModal();
      }
    } else if (baseModalContext.mode === 'NEW') {
      console.log('handleSave triggered');
      handleModeChange('CAST');
      await createCharacterRequest();
      console.log('createCharacterRequest completed');
    } else {
      console.log('Unhandled mode in handleSave:', baseModalContext.mode);
    }
  }, [mode, selectedCharacter, modalData]);

  useEffect(() => {
    setOnSave(() => handleSave);
  }, [handleSave, setOnSave]);

  // Handler functions
  const handleCreateCharacterClick = () => {
    console.log("handleCreateCharacterClick called, setting mode to NEW");
    setIsEditingCharacter(true);
    // setSelectedCharacter({ id: "", name: "", description: "", image: "", scene: [] });
    baseModalContext.setMode("NEW");
  };

  if (!isOpen) {
    return null;
  }

  const handleCharacterUpdate = (updatedCharacter: Partial<TCharacter>) => {
    console.log('Character updated in parent component:', updatedCharacter);
    setCharactersList(prevCharacters =>
      prevCharacters.map(char =>
        char.id === updatedCharacter.id
          ? { ...char, ...updatedCharacter }
          : char
      )
    );
    // setSelectedCharacter({ id: "", name: "", description: "", image: "", scene: [] });
    baseModalContext.setAlreadyUsedCharacterImages(prevImages => {
      const newImages = [...prevImages];
      if (updatedCharacter.image && !prevImages.includes(updatedCharacter.image)) {
        newImages.push(updatedCharacter.image);
      }
      return newImages;
    });
  };

  function handleEditCharacter(isEditing: any) {
    setIsEditingCharacter(isEditing);
  }

  const retrieveCharactersRequest = async () => {
    try {
      const result = await storyBoardsApiClient.character.getCharacters({
        storyId: storyId ?? ""
      });
      if (result.status === 200) {
        setCharactersList(result.data.characters);
        baseModalContext.setAlreadyUsedCharacterImages(result.data.characters.map((character: TCharacter) => character.image) ?? []);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const removeCharacterFromState = ({ id }: { id: string }) => {
    let array = [...charactersList];
    array = array.filter((character) => character?.id !== id);

    setCharactersList([...array]);
    // setSelectedCharacter({ id: "", name: "", description: "", image: "", scene: [] });
    baseModalContext.setAlreadyUsedCharacterImages(array.map((character: TCharacter) => character.image) ?? []);
  };

  const Cast = (): JSX.Element => {
    return (
      <CastUI
        removeCharacterFromState={removeCharacterFromState}
        handleCreateCharacterClick={handleCreateCharacterClick}
        switchMode={switchMode}
        UImode="light"
        CastUiMode="UPDATE"
      />
    );
  };

  const updateStyleRequest = async ({ value, title, styleClientIndex }: { value: string, title: string, styleClientIndex: number }) => {

    setSelectedStyle(value);
    baseModalContext.setStyleClientIndex(styleClientIndex);
    try {
      const result = await storyBoardsApiClient.story.updateStory({
        id: storyId ?? "",
        style: value,
      });
      if (result.status === 200) {
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const Viewer = (): JSX.Element => {
    // const imagesToShow = imageData ? [imageData] : carouselImages;

    const { images, shot, scenes } = baseModalContext.modalData;
    console.log({ images, shot, scenes })

    return (
      <div>
        <Carousel images={images} shot={shot} scenes={scenes} />
      </div>
    );
  };

  let modalContent: React.ReactNode;

  switch (baseModalContext.mode) {
    case "CAST":
      console.log("basemodal:", mode);
      modalContent = <Cast />;
      break;

    case "NEW":
    case "UPDATE":
    case "AI_FOUND":
      modalContent = (
        <div>
            <CharacterSelector
              onCharacterCreated={handleCharacterCreated}
              onCharacterUpdated={handleCharacterUpdate}
              onClose={() => setIsEditingCharacter(false)}
              onEditCharacter={handleEditCharacter}
              onModeChange={handleModeChange}
            />
        </div>
      );
      break;
    case "STYLE":
      modalContent = (
        <div>
          <StyleSelector
            updateStyleRequest={updateStyleRequest}
            selectedStyle={selectedStyle}
            setSelectedStyle={setSelectedStyle}
          />
        </div>
      );
      break;
    case "VIEWER":
      modalContent = <div><Viewer /></div>;
      break;
    case "SUBSCRIBE":
      modalContent = <div className="w-full h-full"><Subscribe trigger={baseModalContext.modalData.trigger} /></div>;
      break;
    case "NEW_PROJECT":
      modalContent = <CreateProject />;;
      break;
    case "EDIT_CHARACTER":
      modalContent = <div className="w-full h-full"><EditCharacter /></div>;
      break;
    case "RENAME_PROJECT":
      modalContent = <div className="w-full h-full"><RenameProject /></div>;
      break;
    case "CONFIRM_DOWNGRADE":
      modalContent = <div className="w-full h-full"><DowngradeModal /></div>;
      break;
    case "CONFIRM":
      modalContent = <div className="w-full h-full"><ConfirmDelete /></div>;
      break;
    case "SUBSCRIPTION_RESULT":
      modalContent = <div className="w-full h-full"><SubscriptionResult /></div>;
      break;
    case "FIRST_LOGIN_EMAIL_CONFIRM":
      console.log("GET_STARTED mode")
      modalContent = <div className="w-full h-full"><FirstLoginEmailConfirm /></div>;
      break;
    case "GET_STARTED":
      console.log("GET_STARTED mode")
      modalContent = <div className="w-full h-full"><GetStarted /></div>;
      break;
    case "VIDEO":
      console.log("VIDEO mode")
      modalContent = <div className="w-[600px] h-[400px]"><VideoModal /></div>;
      break;
    case "VIDEO_DOWNLOAD":
      modalContent = <div className="w-[600px] h-[400px]"><VideoDownloadModal /></div>;
      break;
    default:
      modalContent = null;
      break;
    }

  const switchMode = (newMode: string, { character }: { character: TCharacter }) => {
    console.log(`switchMode called, setting mode to ${newMode}, characterId: `, character);
    baseModalContext.setMode(newMode as TModalMode);
    baseModalContext.setSelectedCharacter(character);
  };

  // Handler to set active button
  const handleButtonClick = (button: string) => {
    setActiveButton(button);
  };

  // Conditional styling based on active state
  const buttonStyles = (button: string) => {
    return activeButton === button
      ? "bg-neutral-700 text-neutral-200" // Active button styles
      : "bg-transparent border border-neutral-500 text-neutral-700"; // Inactive button styles (outlined)
  };

  // Determine the label and onClick handler for the Save button
  const saveButtonLabel =  "Save";
  
  let saveButtonOnClick = () => {
    console.log("click", selectedCharacter, activeButton);

    if (mode === "STYLE") {
        // In STYLE mode, always save and close the modal
        baseModalContext.onSave();
        baseModalContext.closeModal();
    } else if (mode === "NEW" || mode === "AI_FOUND" || mode === "UPDATE" ) {
        // In NEW mode, perform checks before saving or toggling
        if (selectedCharacter) {
            baseModalContext.onSave();
            baseModalContext.closeModal(); // Close modal after saving
        }
    }
  };

  return ReactDOM.createPortal(
    (
      <div ref={ref} className="fixed inset-0 w-screen h-screen flex items-center justify-center px-2 xs:px-4 lg:px-0">
        <div className="modal-backdrop bg-black bg-opacity-50 absolute inset-0 z-40" onClick={() => mode !== "FIRST_LOGIN_EMAIL_CONFIRM" && baseModalContext.closeModal()}></div>
        <div id="base-modal" className="modal-container max-w-[50%] max-h-[80%] rounded-xl bg-neutral-100 bg-opacity-80 backdrop-blur-lg p-4 flex flex-col z-50">
          <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
         {/* Title Section */}
          {["CAST", "NEW", "UPDATE", "AI_FOUND", "STYLE"].includes(mode!) && (
            <div className={`flex flex-col items-center pb-6 ${["CAST", "NEW", "UPDATE", "AI_FOUND", "STYLE"].includes(mode!) && "pt-6"}`}>
              {["NEW", "UPDATE"].includes(mode!) ? (
                <span className="isolate inline-flex rounded-md shadow-sm">
                  {/* Your existing content for NEW and UPDATE modes */}
                </span>
              ) : (
                <>
                  <h2 className="text-xl font-semibold text-neutral-800">
                    {mode === "CAST" && "Cast"}
                    {mode === "AI_FOUND" && (
                      <>
                        Choose the actor to play:{" "}
                        <span className="font-bold uppercase">
                          {baseModalContext.modalData?.character?.name}
                        </span>
                      </>
                    )}
                    {mode === "STYLE" && "Styles"}
                  </h2>
                  {/* Add Character Description */}
                  {baseModalContext.modalData?.character?.description && (
                    <p className="mt-2 text-gray-600 text-center max-w-md">
                      {baseModalContext.modalData.character.description}
                    </p>
                  )}
                </>
              )}
            </div>
          )}

            {/* Main Content Section */}
            <div className="flex-1 overflow-y-auto w-full px-4 min-h-0"> {/* Adjust mb-16 to provide space for the fixed footer */}
              {modalContent}
            </div>
            {["NEW", "UPDATE", "STYLE", "AI_FOUND"].includes(mode!) && (
              <div className="fixed-footer flex gap-x-4 justify-end pr-2 py-2 w-full">
                <button
                  className="text-neutral-500 hover:bg-neutral-300 border border-neutral-500 rounded py-2 px-4 ml-2 w-24"
                  onClick={() => {
                    onClose(); 
                    setIsEditingCharacter(false);
                    baseModalContext.closeModal();
                  }}
                >
                  Cancel
                </button>
                <div className="flex justify-start space-x-2">
                  {/* Conditionally rendered button for NEW, UPDATE, STYLE modes */}
                  {["NEW", "UPDATE", "STYLE", "AI_FOUND"].includes(mode!) && (
                    <button
                      className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 w-24"
                      onClick={saveButtonOnClick}
                    >
                      {saveButtonLabel}
                    </button>
                  
                  )}

                  {/* Additional button specifically for CAST mode */}
                  {mode === "CAST" && (
                    <button
                      className="text-white bg-neutral-500 hover:bg-neutral-600 rounded py-2 px-4 w-24"
                      onClick={() => {
                        onClose();
                        setIsEditingCharacter(false);
                        baseModalContext.setIsOpen(false);
                      }}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>

            )}
          </div>
        </div>
      </div>

    ),
    document.body
  );
}

export default BaseModal;

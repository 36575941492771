import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { storyBoardsApiClient } from '../../../../../core/config/api';
import { TCharacter } from '../../../character/Character';
import { useBaseModalContext } from '../BaseModalContext';
import CastUI from './castUI';
import { useAppContext } from '../../../../../core/utils/AppContext';

interface CastUIWrapperProps {
  mode: 'light' | 'dark';
}

const CastUIWrapper: React.FC<CastUIWrapperProps> = ({ mode }) => {
  const { storyId } = useParams();
  const { charactersList, setCharactersList } = useAppContext();
  const baseModalContext = useBaseModalContext();

  console.log('CastUIWrapper renders... with charactersList:', charactersList);

  useEffect(() => {
    retrieveCharactersRequest();
  }, []);

  const retrieveCharactersRequest = async () => {
    try {
      const result = await storyBoardsApiClient.character.getCharacters({
        storyId: storyId ?? ''
      });
      if (result.status === 200) {
        // Check if the fetched characters are different from the current list
        const fetchedCharacters = result.data.characters;
        const isDifferent = JSON.stringify(fetchedCharacters) !== JSON.stringify(charactersList);
        
        if (isDifferent) {
          if (fetchedCharacters != charactersList){
            setCharactersList(fetchedCharacters);
          }
          baseModalContext.setAlreadyUsedCharacterImages(
            fetchedCharacters.map((character: TCharacter) => character.image) ?? []
          );
        }
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.log({ error });
      toast.error('Something went wrong');
    }
  };  

  const removeCharacterFromState = ({ id }: { id: string }) => {
    setCharactersList(prevList => prevList.filter(character => character?.id !== id));
    baseModalContext.setAlreadyUsedCharacterImages(prevImages =>
      prevImages.filter(image => charactersList.find(c => c.id !== id)?.image !== image)
    );
  };

  const handleCreateCharacterClick = () => {
    baseModalContext.openModal({
      mode: "CREATE",
      data: { character: { id: '', name: '', description: '', image: '', scene: [] } }
    });
  };

  const handleCharacterClick = (newMode: string, { character }: { character: TCharacter }) => {
    console.log('clicked character:', character);
    baseModalContext.setMode(newMode);
    console.log(`mode set to: ${newMode}`);
    console.log('data passed to openModal:', { character });
    baseModalContext.openModal({
      mode: newMode,
      data: { character }
    });
  }

  const updateCharacterInState = (updatedCharacter: TCharacter) => {
    console.log(
      `CastUIWrapper - Attempting to update character in state`);

    // Find the existing character by ID
    const existingCharacter = charactersList.find(
      (character) => character.id === updatedCharacter.id
    );

    if (!existingCharacter) {
      // Character does not exist in the list; add it
      console.log(
        `Character with ID ${updatedCharacter.id} not found. Adding to the list.`
      );
      setCharactersList((prevList) => [...prevList, updatedCharacter]);
      return;
    }

    // Perform a deep comparison between existing and updated characters
    const isEqual = JSON.stringify(existingCharacter) === JSON.stringify(updatedCharacter);
    // Alternatively, using lodash's isEqual for better performance and reliability:
    // const isEqual = isEqual(existingCharacter, updatedCharacter);

    if (!isEqual) {
      console.log(
        `Character with ID ${updatedCharacter.id} has changes. Updating the list.`
      );
      setCharactersList((prevList) =>
        prevList.map((character) =>
          character.id === updatedCharacter.id ? updatedCharacter : character
        )
      );
    } else {
      console.log(
        `No changes detected for character with ID ${updatedCharacter.id}. State not updated.`
      );
    }
  };

  return (
    <CastUI
      removeCharacterFromState={removeCharacterFromState}
      handleCreateCharacterClick={handleCreateCharacterClick}
      switchMode={handleCharacterClick}
      updateCharacterInState={updateCharacterInState}
      UImode={mode}
      CastUiMode="AI_FOUND"
    />
  );
};

export default CastUIWrapper;

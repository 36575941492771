import { HttpClient } from "../client";
import { AxiosResponse } from 'axios';

type CustomCharacterResponse = AxiosResponse & {
  data: TCustomCharacter[];
};

type TCustomCharacter = {
  id: string;
  image: string;
};

export class Character extends HttpClient {
  private readonly prefix: string = "characters";
  private readonly customPrefix: string = "customcharacters";

  public constructor(url: string) {
    super(url);
  }

  public createCharacter = ({
    name,
    description,
    image,
    storyId,
  }: {
    name: string;
    description: string;
    image: string,
    storyId: string,
  }) => {
    return this.instance.post<{ status: any; data: any }>(`stories/${storyId}/${this.prefix}`, {
      name,
      description,
      image,
    });
  };

  public getCharacter = ({ id }: { id: string }) => {
    return this.instance.get<{ status: any; data: any }>(
      `/${this.prefix}/${id}`
    );
  };

  public getCharacters = ({ storyId }: { storyId: string }) => {
    return this.instance.get<{ status: any; data: any }>(`stories/${storyId}/${this.prefix}`);
  };

  public updateCharacter = ({
    id,
    storyId,
    name,
    description,
    image,
  }: {
    id: string;
    storyId: string;
    name?: string;
    description?: string;
    image?: string;
  }) => {
    return this.instance.patch<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`,
      {
        name,
        description,
        image      }
    );
  };

  public deleteCharacter = ({ id, storyId }: { id: string, storyId: string }) => {
    return this.instance.delete<{ status: any; data: any }>(
      `stories/${storyId}/${this.prefix}/${id}`
    );
  };

  public createCustomCharacter = (file: File): Promise<AxiosResponse<{ status: any; data: any }>> => {
    console.log("createCustomCharacter method called");
  
    const formData = new FormData();
    formData.append('image', file);
  
    const url = `/${this.customPrefix}`;
    // console.log("API endpoint:", this.instance.defaults.baseURL + url);
  
    return this.instance.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };
  

  // Adjusted method: Removed 'userId' parameter and updated the URL
  public findAllCustomCharacters = async (): Promise<TCustomCharacter[]> => {
    const url = `/${this.customPrefix}`; // Updated URL without 'userId'
    // console.log(`[Character API] Sending GET request to: ${this.instance.defaults.baseURL}${url}`);
  
    try {
      const response = await this.instance.get(url);
      // console.log(`[Character API] Response received:`, response);
  
      // Manually extract and cast the data as TCustomCharacter[]
      const customCharacters = response.data as TCustomCharacter[];
      return customCharacters;
    } catch (error: unknown) {
      console.error(`[Character API] Error in findAllCustomCharacters:`, error);
  
      if (error instanceof Error) {
        console.error('Error message:', error.message);
  
        const axiosError = error as any;
        if (axiosError.response) {
          console.error('Error response:', {
            data: axiosError.response.data,
            status: axiosError.response.status,
            headers: axiosError.response.headers,
          });
        } else if (axiosError.request) {
          console.error('Error request:', axiosError.request);
        }
      } else {
        console.error('An unknown error occurred');
      }
  
      throw error;
    }
  };

  // Adjusted method: Removed 'userId' parameter and updated the URL
  public deleteCustomCharacter = ({ customCharacterId }: { customCharacterId: string }) => {
    const url = `/${this.customPrefix}/${customCharacterId}`; // Updated URL without 'userId'
    console.log(`[Character API] Sending DELETE request to: ${this.instance.defaults.baseURL}${url}`);
  
    return this.instance.delete<{ status: any; message: string }>(url)
      .then(response => {
        console.log(`[Character API] Custom character deleted successfully:`, response.data);
        return response;
      })
      .catch(error => {
        console.error(`[Character API] Error deleting custom character:`, error);
        throw error;
      });
  };
}

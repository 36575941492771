import React from 'react';
import Character from '../../../character/Character';
import { TCharacter } from '../../../character/Character';
import { useAppContext } from '../../../../../core/utils/AppContext';

interface CastUIProps {
  removeCharacterFromState: (params: { id: string }) => void;
  handleCreateCharacterClick: () => void;
  switchMode: (newMode: string, params: { character: TCharacter }) => void;
  updateCharacterInState?: (character: TCharacter) => void;
  UImode: 'light' | 'dark';
  CastUiMode: 'UPDATE' | 'AI_FOUND';
}

const CastUI: React.FC<CastUIProps> = ({
  removeCharacterFromState,
  handleCreateCharacterClick,
  switchMode,
  updateCharacterInState,
  UImode,
  CastUiMode
}) => {
  const bgColor = UImode === 'light' ? 'bg-transparent' : 'bg-neutral-900';
  const textColor = UImode === 'light' ? 'text-neutral-800' : 'text-neutral-200';
  const borderColor = UImode === 'light' ? 'border-neutral-500' : 'border-neutral-400';
  const hoverBgColor = UImode === 'light' ? 'hover:bg-neutral-400' : 'hover:bg-neutral-600';
  const { charactersList } = useAppContext();

  const handleClick = (character: TCharacter) => {
    if (CastUiMode === "UPDATE") {
      switchMode("UPDATE", { character: { ...character } });
    } else {
      switchMode("AI_FOUND", { character: { ...character } });
    }
  };

  // Ghost Card Component
  const ghostCard = (
    <div
      className="cursor-pointer min-w-24 aspect-square"
      onClick={handleCreateCharacterClick}
    >
      <div
        className={`bg-transparent rounded-lg border-2 border-dashed ${borderColor} ${hoverBgColor} flex justify-center items-center w-full h-full`}
      >
        <span className={`text-4xl ${textColor}`}>+</span>
      </div>
    </div>
  );

  return (
    <div id="CastUI" className={`w-full ${bgColor} ${textColor} flex flex-col h-full`}>
      {UImode === "light" ? (
      // Light Mode Layout
      <div
        id="light_mode"
        className="w-full mx-auto flex-1 overflow-y-auto"
      >
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {ghostCard}
          {charactersList?.map((character: TCharacter) => (
            <div
              key={character?.id}
              onClick={() => handleClick(character)}
              className="cursor-pointer w-full aspect-square"
            >
              <Character
                key={`${character?.id}-${character?.image}`}
                character={character}
                removeCharacterFromState={removeCharacterFromState}
                mode={UImode}
                updateCharacterInState={updateCharacterInState}
              />
            </div>
          ))}
        </div>
      </div>
          ) : (
            // Dark Mode Layout using Grid
            <div
              id="dark_mode"
              className="w-full flex flex-col flex-1 overflow-y-auto"
              style={{ maxHeight: '100%' }}
            >
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 p-4">
                
                {charactersList?.map((character: TCharacter) => (
                  <div
                    key={character?.id}
                    onClick={() => handleClick(character)}
                    className="cursor-pointer w-full aspect-square"
                  >
                    <Character
                      character={character}
                      removeCharacterFromState={removeCharacterFromState}
                      mode={UImode}
                      updateCharacterInState={updateCharacterInState}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    };

export default CastUI;

// InpaintCanvas.tsx

import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  Ref,
} from "react";

// Define the props for the InpaintCanvas component
interface InpaintCanvasProps {
  className?: string;
  preferredStrokeStyle: string; // Current selected brush color
  preferredLineWidth: number; // Current selected brush size
  isEraserOn: boolean; // Indicates if the eraser is active
  isInpaintMode: boolean; // Indicates if the canvas is in inpaint mode
}

// Define the methods that the parent component can call via ref
export interface InpaintCanvasHandle {
  clearCanvas: () => void;
  getCanvasDataURL: () => string;
}

// Define the structure for each drawn curve (for potential future enhancements)
interface Curve {
  path: Path2D;
  strokeStyle: string;
  lineWidth: number;
  compositionOperation: GlobalCompositeOperation;
}

// Forward the ref to allow parent components to access methods
export const InpaintCanvas = forwardRef<InpaintCanvasHandle, InpaintCanvasProps>(
  (
    {
      className,
      preferredStrokeStyle,
      preferredLineWidth,
      isEraserOn,
      isInpaintMode,
    },
    ref
  ) => {
    // Ref to access the canvas DOM element
    const canvasRef = useRef<HTMLCanvasElement>(null);

    // State to manage drawing status and the last point
    const isDrawing = useRef(false);
    const lastPoint = useRef<{ x: number; y: number } | null>(null);

    // Optional: Store drawn curves for future enhancements (e.g., undo functionality)
    const [curves, setCurves] = useState<Curve[]>([]);

    // Expose methods to the parent component
    useImperativeHandle(ref, () => ({
      clearCanvas,
      getCanvasDataURL,
    }));

    // Initialize the canvas and handle resizing
    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      // Set canvas dimensions to match its parent element
      const resizeCanvas = () => {
        if (!canvas.parentElement) return;
        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientHeight;
        // Optionally, redraw existing curves after resizing
        redrawAll(ctx);
      };

      // Initial resize
      resizeCanvas();

      // Resize on window resize
      window.addEventListener("resize", resizeCanvas);
      return () => window.removeEventListener("resize", resizeCanvas);
    }, [curves]);

    // Redraw all curves (useful after resizing)
    const redrawAll = (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      curves.forEach((curve) => {
        ctx.globalCompositeOperation = curve.compositionOperation;
        ctx.lineWidth = curve.lineWidth;
        ctx.strokeStyle = curve.strokeStyle;
        ctx.lineCap = "round";
        ctx.beginPath();
        ctx.stroke(curve.path);
      });
    };

    // Handle mouse down event
    const handleMouseDown: React.MouseEventHandler<HTMLCanvasElement> = (
      event
    ) => {
      isDrawing.current = true;
      lastPoint.current = getMousePos(event);
    };

    // Handle mouse move event
    const handleMouseMove: React.MouseEventHandler<HTMLCanvasElement> = (
      event
    ) => {
      if (!isDrawing.current || !lastPoint.current) return;
      const ctx = canvasRef.current?.getContext("2d");
      if (!ctx) return;

      const currentPoint = getMousePos(event);
      const newPath = new Path2D();
      newPath.moveTo(lastPoint.current.x, lastPoint.current.y);
      newPath.lineTo(currentPoint.x, currentPoint.y);

      // Set drawing properties
      ctx.lineWidth = preferredLineWidth;
      ctx.strokeStyle = isInpaintMode ? "#FFFFFF" : preferredStrokeStyle; // Override to white in inpaint mode
      ctx.lineCap = "round";
      ctx.globalCompositeOperation = isEraserOn ? "destination-out" : "source-over";

      // Draw the path
      ctx.stroke(newPath);

      // Save the curve (optional)
      const compositionOp = isEraserOn ? "destination-out" : "source-over";
      console.log('Saving curve with compositionOperation:', compositionOp);
      setCurves((prevCurves) => [
        ...prevCurves,
        {
          path: newPath,
          strokeStyle: isInpaintMode ? "#FFFFFF" : preferredStrokeStyle,
          lineWidth: preferredLineWidth,
          compositionOperation: isEraserOn ? "destination-out" : "source-over",
        },
      ]);

      // Update the last point
      lastPoint.current = currentPoint;
    };

    // Handle mouse up and mouse leave events
    const handleMouseUp: React.MouseEventHandler<HTMLCanvasElement> = () => {
      isDrawing.current = false;
      lastPoint.current = null;
    };

    // Handle touch start event
    const handleTouchStart: React.TouchEventHandler<HTMLCanvasElement> = (
      event
    ) => {
      event.preventDefault(); // Prevent scrolling
      isDrawing.current = true;
      lastPoint.current = getTouchPos(event);
    };

    // Handle touch move event
    const handleTouchMove: React.TouchEventHandler<HTMLCanvasElement> = (
      event
    ) => {
      event.preventDefault(); // Prevent scrolling
      if (!isDrawing.current || !lastPoint.current) return;
      const ctx = canvasRef.current?.getContext("2d");
      if (!ctx) return;

      const currentPoint = getTouchPos(event);
      const newPath = new Path2D();
      newPath.moveTo(lastPoint.current.x, lastPoint.current.y);
      newPath.lineTo(currentPoint.x, currentPoint.y);

      // Set drawing properties
      ctx.lineWidth = preferredLineWidth;
      ctx.strokeStyle = isInpaintMode ? "#FFFFFF" : preferredStrokeStyle; // Override to white in inpaint mode
      ctx.lineCap = "round";
      ctx.globalCompositeOperation = isEraserOn ? "destination-out" : "source-over";

      // Draw the path
      ctx.stroke(newPath);

      // Save the curve (optional)
      const compositionOp = isEraserOn ? "destination-out" : "source-over";
      console.log('Saving curve with compositionOperation:', compositionOp);
      setCurves((prevCurves) => [
        ...prevCurves,
        {
          path: newPath,
          strokeStyle: isInpaintMode ? "#FFFFFF" : preferredStrokeStyle,
          lineWidth: preferredLineWidth,
          compositionOperation: isEraserOn ? "destination-out" : "source-over",
        },
      ]);

      // Update the last point
      lastPoint.current = currentPoint;
    };

    // Handle touch end event
    const handleTouchEnd: React.TouchEventHandler<HTMLCanvasElement> = () => {
      isDrawing.current = false;
      lastPoint.current = null;
    };

    // Utility function to get mouse position relative to the canvas
    const getMousePos = (
      event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
    ): { x: number; y: number } => {
      const canvas = canvasRef.current;
      if (!canvas) return { x: 0, y: 0 };
      const rect = canvas.getBoundingClientRect();
      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    };

    // Utility function to get touch position relative to the canvas
    const getTouchPos = (
      event: React.TouchEvent<HTMLCanvasElement>
    ): { x: number; y: number } => {
      const canvas = canvasRef.current;
      if (!canvas) return { x: 0, y: 0 };
      const rect = canvas.getBoundingClientRect();
      const touch = event.touches[0];
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      };
    };

    // Method to clear the canvas
    const clearCanvas = () => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (ctx && canvas) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setCurves([]); // Reset curves
      }
    };

    // Method to get the canvas data URL (optional, for mask processing)
    const getCanvasDataURL = (): string => {
      const canvas = canvasRef.current;
      if (!canvas) return "";
    
      // Create an off-screen canvas
      const offScreenCanvas = document.createElement("canvas");
      offScreenCanvas.width = canvas.width;
      offScreenCanvas.height = canvas.height;
      const ctx = offScreenCanvas.getContext("2d");
      if (!ctx) return "";
    
      // Fill the background with black
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
    
      // Redraw all curves with white strokes
      curves.forEach((curve) => {
        ctx.globalCompositeOperation = "source-over"; // Use source-over for all strokes
        ctx.lineWidth = curve.lineWidth;
        ctx.strokeStyle = "#FFFFFF"; // Force stroke color to white
        ctx.lineCap = "round";
        ctx.beginPath();
        ctx.stroke(curve.path);
      });
    
      // Get the data URL from the off-screen canvas
      return offScreenCanvas.toDataURL("image/png");
    };

    return (
      <canvas
        id="inpaint-canvas"
        className={className}
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{ cursor: isEraserOn ? "crosshair" : "pointer" }}
      />
    );
  }
);

export default InpaintCanvas;

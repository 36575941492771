import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CustomLoadingSpinner } from '../../loading/CustomLoadingSpinner';

interface Image {
  url: string;
}

interface GalleryModalProps {
  isOpen: boolean;
  closeModal: () => void;
  images: Image[];
  loadingImageUrl: string | null;
  setImageRequest: (params: { image: any }) => Promise<void>;
  onImageSelect?: (image: Image) => void; 
  mode?: 'default' | 'IMG2IMG';
}

const GalleryModal: React.FC<GalleryModalProps> = ({ 
  isOpen, 
  closeModal, 
  images, 
  loadingImageUrl, 
  setImageRequest,
  onImageSelect,
  mode = 'default'
}) => {
  if (!isOpen) return null;

  const validImages = images.filter((image) => image.url !== "https://story-board-images.s3.us-west-1.amazonaws.com/placeholder_result.webp");

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-40">
      <div className="modal-backdrop bg-black bg-opacity-50 absolute inset-0" onClick={closeModal}></div>
      <div className="modal-container mx-4 max-h-[90vh] max-w-[85vw] md:max-w-[90vw] lg:max-w-[1140px] h-auto lg:h-[650px] rounded-xl bg-neutral-100 bg-opacity-80 backdrop-blur-lg p-4 flex flex-col overflow-auto">
        {/* Title Section */}
        <div className="flex justify-center items-center pb-6 pt-6">
          <h2 className="text-xl font-semibold text-neutral-800">
            Previously generated Images
          </h2>
        </div>

        {/* Close Button */}
        <button
          className="absolute top-0 right-0 mt-2 mr-4 text-2xl font-semibold text-neutral-500 hover:text-neutral-700"
          aria-label="Close"
          onClick={closeModal}
        >
          &times;
        </button>

        <div id="image-gallery" className={`grid grid-cols-1 ${validImages.length === 1 ? "lg:px-28 sm:px-24 px-2" : ""}
          ${validImages.length >= 2 ? "sm:grid-cols-2" : ""} ${validImages.length >= 3 && "md:grid-cols-3"} ${validImages.length >= 4 && "lg:grid-cols-4"}
          gap-2 p-4 items-center overflow-auto w-full`} style={{ maxHeight: "540px" }}
        >
          {validImages.length > 0 ? (
            validImages.map((image: Image) => (
              <div key={image.url} className="relative w-full flex justify-center">
                {loadingImageUrl === image.url && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
                    <CustomLoadingSpinner size={30} />
                  </div>
                )}
                <img
                  className="cursor-pointer transition-all h-auto w-full duration-300 ease-in-out transform hover:scale-105"
                  style={{ objectFit: "cover" }}
                  onClick={() => {
                    if (mode === 'IMG2IMG' && onImageSelect) {
                      onImageSelect(image);  
                      closeModal();  
                    } else {
                      setImageRequest({ image }); 
                    }
                  }}
                  src={image.url}
                  alt="shot-image"
                />
              </div>
            ))
          ) : (
            <p className="text-lg text-center py-24 lg:py-0 lg:px-12 lg:mt-48">
              Nothing here yet. Generate some images for this Shot and they will display here.
            </p>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")! // Targeting the modal-root div
  );
};

export default GalleryModal;
import { useSelector } from 'react-redux';

type PlanType = 'Free' | 'Indie' | 'Pro' | 'Studio';

interface PlanCosts {
    image: number;
    inpaint: number;
    img2Img: number;
    wizard: number;
}

interface PlanInfo {
    isFree: boolean;
    isPlan: PlanType;
    costs: PlanCosts;
}

export const freeUserCosts: PlanCosts = {
    image: 2,
    inpaint: 8,
    img2Img: 15,
    wizard: 20
};

export const paidUserCosts: PlanCosts = {
    image: 1,
    inpaint: 5,
    img2Img: 10,
    wizard: 15
};

const useUserPlan = (): PlanInfo => {
    const account = useSelector((state: any) => state.app?.account);
    const isAdmin = process.env.REACT_APP_ADMIN === 'true';

    const checkUserPlan = (): PlanInfo => {
        const subscription = account?.subscription;
        const subscriptionId = subscription?.id;

        if (isAdmin) {
            console.log("admin override");
            return { isFree: false, isPlan: 'Studio', costs: paidUserCosts };
        }

        if (!subscription || Object.keys(subscription).length === 0 || subscriptionId === 'tier-free') {
            // User is on the free plan if the subscription is empty or explicitly 'tier-free'
            return { isFree: true, isPlan: 'Free', costs: freeUserCosts };
        }

        if (subscriptionId === 'tier-indie') {
            // User is on a paid plan (indie tier)
            return { isFree: false, isPlan: 'Indie', costs: paidUserCosts };
        }

        // Default to free plan if no recognized subscription ID is found
        return { isFree: true, isPlan: 'Free', costs: freeUserCosts };
    };

    return checkUserPlan();
};

export default useUserPlan;


import { useEffect, useState, useRef } from "react";
import { storyBoardsApiClient } from "../../config/api";
import { setAccount } from "../../redux/actions/accountActions";
import store from "../../redux/store";
import { useAppContext } from "../../utils/AppContext";
import useAnalyticsClientId from "../../utils/clientID";

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const lastPart = parts.pop();
    if (lastPart !== undefined) {
      return lastPart.split(";").shift();
    }
  }
  return undefined;
}

export function useAuth() {
  const [data, setData] = useState(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { setUser } = useAppContext();
  const clientId = useAnalyticsClientId();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await storyBoardsApiClient.user.getProfile();
        const userId = result.data.id;
        if (result.status === 200) {
          console.log({ profile: result });

          if (clientId && clientId !== result.data.clientId && userId) {
            console.log("Client ID changed, updating profile...");
            const updateResult = await storyBoardsApiClient.user.updateClientId({
              userId: userId,
              clientId: clientId,
            });
            if (updateResult.status === 200) {
              result.data.clientId = clientId;
            }
          }

          setData(result);
          // @ts-ignore
          store.dispatch(setAccount({ data: result.data }));
          setUser(result.data);
        }
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [clientId, setUser]); 

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(async () => {
        try {
          await storyBoardsApiClient.auth.refreshSession();
        } catch (error) {
          console.error("Failed to refresh session:", error);

          const accessToken = getCookie("access_token");
          setUser(null);
          console.warn("Redirecting to login page due to session refresh failure.");
          
          // Redirect to login page
          window.location.href = "/sign-in";
        }
      }, 4 * 60 * 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  return [data, loading, error];
}

import { useSelector } from "react-redux";
import { sendEventToGoogleTagManager } from "../../../../utils/googleTagManager";
import { HttpClient } from "../client";
import { AxiosResponse } from "axios";
import { useAppContext } from "../../../../utils/AppContext";


export type GptRequestData = {
  storyId: string;
  requestType: string;
  data: {
    sceneDescription: string;
    cameraAngle: string;
    prompt: string;
    // Add more properties as needed
  };
};

type GptResponse = {
  gptResponse?: any;
  prompt?: string;
};

type ApiResponse<T> = {
  data: any;
};

export class GptRequest extends HttpClient {
  private readonly prefix: string = "gpt/process";

  public constructor(url: string) {
    super(url);
  }

  public processPrompt = async (gptData: GptRequestData): Promise<GptResponse> => {

    console.log("[GptRequest] Sending request with data:", gptData);
  
    try {
      const response = await this.instance.post<ApiResponse<GptResponse>>(`/gpt/process`, gptData);
      
      return response.data.gptResponse; 
    } catch (error) {
      console.error("[GptRequest] Error processing GPT prompt:", error);
      throw error;
    }
  };
}


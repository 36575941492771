import { useState } from 'react';

export const useImageValidator = () => {
  const [error, setError] = useState<string | null>(null);

  const validateAndProcessImage = async (file: File): Promise<File | null> => {
    console.log("Starting validateAndProcessImage");
    setError(null);

    // No need to validate file type anymore
    console.log("File type:", file.type);

    const image = new Image();
    image.src = URL.createObjectURL(file);
    console.log("Image URL created");

    return new Promise((resolve, reject) => {
      image.onload = async () => {
        console.log("Image loaded");
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          console.error("Could not create 2D context");
          setError('Something went wrong.');
          return reject(null);
        }

        const { width, height } = image;
        console.log("Image dimensions:", width, "x", height);

        // Validate dimensions
        if (width < 512 || height < 512) {
          console.error("Image dimensions are too small");
          setError('Image dimensions are too small. Minimum is 512 pixels in any dimension.');
          return reject(setError);
        }

        // Crop the image to a square
        const sideLength = Math.min(width, height);
        const startX = (width - sideLength) / 2;
        const startY = (height - sideLength) / 2;
        console.log("Cropping image to square:", sideLength, "x", sideLength);

        // Determine the final size
        let finalSize;
        if (sideLength > 1024) {
          finalSize = 1024;
        } else if (sideLength >= 512 && sideLength <= 1024) {
          finalSize = 512;
        } else {
          // This should have been caught by earlier dimension checks
          console.error("Unexpected image dimensions after cropping");
          setError('Something went wrong. Please try again.');
          return reject(setError);
        }

        console.log("Final image size will be:", finalSize, "x", finalSize);

        // Resize and draw the image
        canvas.width = finalSize;
        canvas.height = finalSize;

        ctx.drawImage(
          image,
          startX,
          startY,
          sideLength,
          sideLength,
          0,
          0,
          finalSize,
          finalSize
        );

        // Convert canvas to JPEG
        console.log("Converting canvas to JPEG");

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const jpgFile = new File(
                [blob],
                file.name.replace(/\.[^/.]+$/, "") + '.jpg',
                { type: 'image/jpeg' }
              );
              console.log("JPEG file created");
              resolve(jpgFile);
            } else {
              console.error("Could not convert canvas to blob");
              setError('Something went wrong. Please try another image.');
              reject(setError);
            }
          },
          'image/jpeg',
          1 // Quality parameter from 0 to 1
        );
      };

      image.onerror = () => {
        console.error("Could not load the image");
        setError('Could not load the image.');
        reject(setError);
      };
    });
  };

  return {
    validateAndProcessImage,
    error,
  };
};
